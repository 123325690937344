@import '../../assets/stylesheet/variables.scss';

.component-not-found {
  background-image: $theme-gradient;
  height: 100vh;
  width: 100vw;
  color: #fff;
  padding: 20px;
  font-size: 82px;
  .link {
    font-size: 16px;
    margin-top: 14px;
    cursor: pointer;
  }
}