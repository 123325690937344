@import "assets/stylesheet/variables.scss";

$grey-color: #524e59;
$shadowType1: 0px 0px 14px rgba(0, 0, 0, 0.1);

.teacherselection {
  width: 400px !important;
  padding: 8px 0px 8px 5px !important;
}

.add-pref-header {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .back-btn {
    .ant-btn {
      color: $grey-color;
      font-weight: 400;
      border: none;
      margin: none;
      font-size: 16px;
      width: auto;
      height: auto;
      padding: 0 !important;
      text-transform: uppercase !important;
    }
  }

  h1 {
    line-height: 36px;
    font-size: 24px;
    margin: 0;
    padding: 10px 0 7px 0;
    color: #000;
    font-weight: 500;
  }

  .create-pref-cta {
    display: flex;

    .ant-btn-primary {
      background-color: $magenta-color;
    }

    .export-btn {
      width: 148px;
      background-color: $magenta-color;
      border-radius: 10px;
      box-shadow: $shadowType1;
    }
  }

  .ant-btn {
    text-transform: unset;
  }

  .ant-btn-primary,
  .ant-btn-primary:hover,
  .ant-btn-primary:focus,
  .ant-btn-primary:active,
  .ant-btn-primary:visited {
    background-color: $honey-flower;
    border: none;
    min-width: 130px;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
  }

  .ant-btn-default,
  .ant-btn-default:hover,
  .ant-btn-default:focus,
  .ant-btn-default:active,
  .ant-btn-default:visited {
    background-color: $light-gray-color;
    border: none;
    min-width: 130px;
    height: 40px;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    color: $default-text-color;
  }

  .cancel {
    width: 148px;
    margin: 0 10px;
    background: #ececec;
    border-radius: 8px;
    border: none;
    color: $default-text-color;
    font-weight: 500;
    font-size: 16px;
  }
}

.add-pref-wrapper {
  border-radius: 6px;
  //box-shadow: 1px 1px 1px 0 rgba(44, 11, 74, 0.15);
  background-color: $white-color;
  padding: 25px 0;

  .heading1 {
    font-size: 16px;
    color: $dark-magenta;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 24px;
  }
}

.footer-image {
  position: absolute;
  right: 0;
  bottom: 0;
}

.preference-create-form {
  .ant-form-item-label {
    line-height: normal;
  }

  .ant-form-item .ant-form-item-label label,
  .preference-create-form-label {
    position: relative;
    padding-bottom: 8px;
    min-height: 22px;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    color: $light-gray-color3;
    text-transform: uppercase;

    &:after {
      display: none;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-input,
  .ant-input:focus,
  .ant-input:hover,
  .ant-input:active,
  .ant-select-selection,
  .ant-input-number {
    background: rgba(236, 236, 236, 0.2) !important;
    border-radius: 8px;
    height: 56px;
    //opacity: 0.2;
    border: none;
    box-shadow: none;
    font-size: 20px;
    font-weight: 500;
    color: $default-text-color;
    outline: none;
    padding: 0 16px;
    width: 100%;
  }

  input::placeholder {
    font-size: 16px;
    color: $default-text-color;
    opacity: 0.2;
    font-weight: 500;
  }

  .ant-input-group-addon {
    background: $white-color !important;
    border: none;
    border-radius: 20px 0 0 20px;
    font-size: 20px;
    font-weight: 500;
    color: $default-text-color;
    padding-right: 0;
  }

  .ant-input-group-addon + .ant-input,
  .ant-input-group-addon + .ant-input:hover,
  .ant-input-group-addon + .ant-input:focus {
    border-radius: 0 20px 20px 0;
    padding-left: 4px;
    background: $white-color !important;
  }

  .ant-select-selection__rendered,
  .ant-input-number-input-wrap {
    padding: 0;
    margin: 0;
    line-height: 56px;
  }

  .ant-input-number-input {
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    color: $default-text-color;
  }

  .ant-select-selection-selected-value {
    line-height: 56px;
  }

  textarea.ant-input,
  textarea.ant-input:hover,
  textarea.ant-input:focus,
  textarea.ant-input:active,
  textarea.ant-input:visited {
    min-height: 116px;
    padding: 10px 16px;
  }

  .ant-form-item-children {
    display: block;
  }

  .ant-checkbox-wrapper {
    min-height: 15px;
    margin: 8px 0 0 0;
    line-height: normal;
    font-size: 16px;
    font-weight: 500;
    color: $default-text-color;
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 25.6% 0 0;
  }

  .ant-form-item-children {
    line-height: normal;
  }

  .ant-checkbox:after {
    display: none;
  }

  .ant-checkbox-inner,
  .ant-checkbox-inner:hover {
    height: 24px;
    width: 24px;
    box-sizing: border-box;
    border: solid 2px;
    border-color: $magenta-color !important;
    border-radius: 6px;
    margin-right: 10px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $magenta-color;

    &:after {
      left: 30%;
      border-width: 1px;
    }
  }
}
