@import "assets/stylesheet/variables.scss";

.admin-class-list {
  padding: 25px 20px;
  margin-right: 15px;
  margin-left: 15px;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.07);
  height: 73vh;
  overflow-y: auto;

  .load-more {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: min-content;
    align-items: center;
    justify-content: center;
    background: #fff;
    opacity: 0.9;

    .ant-spin {
      padding: 0 15px;
      display: flex;
    }
  }

  .batch-details
    .container
    .main
    .card-container
    > .ant-tabs-card
    .ant-tabs-tab-active,
  .batch-details
    .container
    .main
    [data-theme="compact"]
    .card-container
    > .ant-tabs-card
    .ant-tabs-tab-active {
    box-shadow: 0px 15px rgba(0, 0, 0, 0.07);
    color: black;
    font-weight: 500;
    font-size: 18px;
  }

  .list-item {
    padding: 15px 10px;
    display: flex;
    border-radius: 5px;

    &:hover {
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
    }

    &.upcoming {
      .item-container {
        &.completed {
          overflow: visible;
        }
        .pointer {
          cursor: pointer;
        }

        .ant-btn {
          border: 2px solid $magenta-color;
          background: white;
          width: auto;
          height: auto;
          padding: 5px 2px;
          box-sizing: border-box;

          span {
            color: $magenta-color;
            font-weight: 500;
            font-size: 12px;
          }
        }

        &:nth-child(1) {
          border-radius: 1px solid black;
          flex-basis: 20%;
          border-right: 2px solid #ebebeb;
          justify-content: flex-start;
        }

        &:nth-child(2) {
          flex-basis: 60%;
          padding-left: 20px;

          p {
            font-weight: 500;
            line-height: 20px;
          }
        }

        &:nth-child(3) {
          flex-basis: 25%;
        }
      }
    }

    &.previous {
      .item-container {
        .ant-btn {
          border: 2px solid $magenta-color;
          width: auto;
          height: auto;
          padding: 5px 2px;
          box-sizing: border-box;

          span {
            color: $magenta-color;
            font-weight: 500;
            font-size: 12px;
          }
        }

        &.completed {
          .ant-btn {
            width: auto;
            height: auto;
            padding: 5px 2px;
            box-sizing: border-box;
            font-weight: 500;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
              color: #219653;
              font-weight: 500;
              font-size: 12px;
            }
          }
        }

        &:nth-child(1) {
          border-radius: 1px solid black;
          flex-basis: 20%;
          border-right: 2px solid #ebebeb;
          justify-content: flex-start;
        }

        &:nth-child(2) {
          flex-basis: 60%;
          padding-left: 20px;

          p {
            font-weight: 500;
            line-height: 20px;
          }
        }

        &:nth-child(3) {
          flex-basis: 21%;
        }

        &:nth-child(4) {
          flex-basis: 21%;
        }
      }
    }

    .item-container {
      overflow: visible !important;
      padding: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;

      .date {
        font-weight: 500;
        color: black;
      }

      .time {
        font-size: 12px;
        margin-top: 5px;
      }

      .zoomlink-input-container {
        display: flex;

        .zoom-link-input {
          margin: 0 5px 0 0 !important;
        }

        .close-icon {
          height: 23px;
          fill: #ff4120;
          padding: 0 8px 0 0;
          width: 30px;
          background: transparent;
        }

        .check-icon {
          height: 23px;
          fill: #219653;
          padding: 0 8px 0 0;
          background: transparent;
        }
      }
    }
    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #f9f9f9;

      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
    }

    .dropdown-content button {
      float: none;
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      text-align: left;
    }

    .dropdown-content button:hover {
      background-color: #ddd;
    }

    .dropdown:hover .dropdown-content {
      display: block;
    }
  }

  .completed {
    .ant-btn {
      border: none !important;

      span {
        color: #219653 !important;
      }
    }
  }
}
