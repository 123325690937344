.student-feedback-form {
  * {
    animation: none !important;
    transition: none !important;
  }
  .feedback-header {
    padding: 55px 55px 0px 55px;
    .ant-btn {
      &:nth-child(1) {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */

        /* Text Grey */
        border: none;
        color: #524e59;
        padding-left: 0px;
      }
      &:nth-child(2) {
        background: #e3e3e4;
        border-radius: 100%;
        width: 20px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
      }
    }
  }
  .student-info {
    padding: 0px 55px;
    display: flex;
    align-items: center;
    margin-top: 30px;
    img {
      height: 55px;
      width: 55px;
      border-radius: 100%;
    }
    .student-name {
      flex: 1;
      padding-left: 15px;
      padding-right: 15px;
      h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;

        /* Text Grey */

        color: #524e59;
      }
      h5 {
        margin-top: 5px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */

        /* Text Grey */

        color: #524e59;

        opacity: 0.5;
      }
    }
    .ant-btn {
      /* #9134D8 */
      width: 150px;
      height: 50px;
      background: #9134d8;
      border-radius: 8px;
      span {
        margin-top: 3px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */

        text-align: center;
        letter-spacing: 0.888889px;

        color: #ffffff;
      }
    }
  }
  .feedback-questions {
    margin-top: 40px;
    overflow-y: auto;
    max-height: 500px;
    .feedback-question {
      height: 133px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      padding: 32px 57px;
      &:nth-child(odd) {
        background: rgba(127, 127, 132, 0.05);
      }
      h4 {
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 11px;
        color: #524e59;
      }
    }
    .answer-options {
      .ant-radio-group-outline {
        display: flex;
      }
      .ant-radio-wrapper {
        background: #ffffff;
        border: 2px solid rgba(127, 127, 132, 0.2);
        box-sizing: border-box;
        border-radius: 55px;
        padding: 10px 20px;
        span {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;

          /* Text Grey */

          color: #524e59;
          .ant-radio-inner {
            border: 2px solid #c4c4c4;
          }
        }
      }
      .ant-radio-wrapper-checked {
        background: rgba(147, 81, 205, 0.1);
        /* #9351CD */

        border: 1px solid #9351cd;
        box-sizing: border-box;
        border-radius: 55px;
        .ant-radio-inner {
          border: 2px solid #c19cdf !important;
          &::after {
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 12px;
            height: 12px;
            background-color: #9134d8;
          }
        }
      }
    }
  }
  .form-padding {
    padding: 50px !important;
  }
}
