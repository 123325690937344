// canvas {
//   position: absolute;
//   left: 0;
//   top: 22%;
//   right: 0;
//   z-index: 1;
// }
// canvas.secondCanvas {
//   z-index: 0;
// }

#vis {
  svg {
    fill: #fff;
  }
}
.container {

  #book-title {
    font-family: georgia, "times new roman", times, serif;
    color: #555;
    font-size: 2.3em;
    text-align: center;
  }
  
  #text-select {
    float:right;
    margin-right: 10px;
  }
  
  #vis {
    overflow: hidden;
    position: relative;
  }
  
  #status {
    padding:0 0 20px 20px;
  }
  
  #controls {
    padding: 20px;
    background-color: #eee;
    margin: 10px;
    width: 350px;
  }
  
  #bubble-background {
    fill: none;
    pointer-events: all;
  }
  
  .bubble-node {
    /* fill: #d1e0f3; */
    fill: #b11be5;
    transition: fill 0.1s;
    fill: radial-gradient(circle at 0 0, #0bbce0, #b11be5);
  }
  
  .bubble-hover {
    /* fill: #b8c7d9; */
    fill: #0bbce0;
  }
  
  .bubble-selected {
    /* fill: #a8c8ee;  */
    // fill: #CEE98F;
    fill: radial-gradient(circle at 0 0, #0bbce0, #b11be5);
    /* stroke: #6081a3; */
    // stroke: #A6BA92;
    // stroke-width: 1.5px;
  }
  
  .active {
    color: #A6BA92
  }
  
  a.bubble-label {
    color: #fff;
    cursor: pointer;
    display: block;
    text-align: center;
    text-decoration: none;
    line-height: 1em;
    position: absolute;
  }
  
  
  .bubble-label .bubble-label-value {
    font: 11px sans-serif;
    white-space: nowrap;
  }
}
.bubble-node {
  background: radial-gradient(circle at 0 0, #0bbce0, #b11be5) !important; 
}