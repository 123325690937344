.children-attending-screen {
    .ant-btn-primary, .ant-btn-primary:hover, .ant-btn-primary:focus, .ant-btn-primary:active, .ant-btn-primary:visited {
        background-color: #9351cd !important;
        border-radius: 8px;
        padding: 15px 20px;
        width: auto;
        height: auto;
    }
    .seperator-20 {
        display: none;
    }
}