// Will setup this using some variable for auto build
// but for now you must comment and un-comment accordingly for 
// euro or freadom build.

// For freadom
$theme-color: #3e2272;
$theme-btn-color: #ff8a23;
$theme-btn-color-hover: #d8751e;
$theme-btn-color-disabled: #ffdcbd;
$theme-gradient: linear-gradient(359deg, #552d83, #432870 64%, #361e5f);

$white-color: #ffffff;
$default-text-color: #524e59;
$magenta-color: #9134D8;
$light-magenta: #f4ebfc;
$athens-gray: #f4f3f7;
$dark-magenta: #4c1a73;
$honey-flower: #491a6c;
$light-gray-color: #ececec;
$light-gray-color2: #c2c5cd;
$light-gray-color3: #979797;
$light-gray-color4: #f0eff1;
$iron-color: #DCDCDE;
$flamingo: #F24E29;

$max-resp-width: "700px";

// For Euro
// $theme-color: #2ba8e0;
// $theme-btn-color: $theme-color;
// $theme-btn-color-hover: #2693c5;
// $theme-btn-color-disabled: #2ba7e060;
// $theme-gradient: linear-gradient(359deg, #2be0ad, #2ba8e0);
