@import "assets/stylesheet/variables.scss";

.children-modal {
  .ant-modal {
    padding-bottom: 0px !important;
  }
  .table-container {
    margin-top: 20px;
    height: 568px;
    overflow-y: auto;
    background: #ffffff;
    box-shadow: 1px 1px 1px rgba(44, 11, 74, 0.15);
    border-radius: 6px;
    padding: 12px;
    padding-top: 5px;

    table {
      border-collapse: separate;
      border-spacing: 0 10px;

      .ant-table-header-column {
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 41px;
        }
      }
    }

    thead {
      th {
        &:nth-child(1) {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          padding-left: 50px;
        }

        &:nth-last-child(1) {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }

        height: 10px;
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 10px;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        border-bottom: 0px;
        text-transform: uppercase;
        color: #524e59;
      }
    }

    td {
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.222222px;
      padding: 30px 3px;
      color: #524e59;
      border-bottom: 1px solid #cccccc;
      border-top: 1px solid #cccccc;
      box-sizing: border-box;

      &:nth-child(1) {
        padding-left: 50px;
        border-left: 1px solid #cccccc;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &:nth-last-child(1) {
        border-right: 1px solid #cccccc;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    a {
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      text-transform: capitalize;
      color: #9351cd;
      border: 1px solid #9351cd;
      box-sizing: border-box;
      border-radius: 5.38365px;
      padding: 10px 18px;
      text-decoration: none;
    }

    .ant-table-placeholder {
      border-bottom: none;
    }
  }
  .report-pill {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      background: #f2bd1d;
      border-radius: 6px;
      text-align: center;
      padding: 5px 10px;
    }

    color: #ffffff;
  }
  .row {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .d-flex {
    display: flex;
    flex-direction: row;
    align-items: center;

    &.reverse {
      flex-direction: column;
    }
  }
  .j-c-c {
    justify-content: center;
  }
  .j-c-sb {
    justify-content: space-between;
  }
  .j-c-se {
    justify-content: space-evenly;
  }
  .j-c-fs {
    justify-content: flex-start;
  }
  .a-i-fs {
    align-items: flex-start;
  }
  .ant-modal-header {
    display: block;
    background-color: white;
    padding: 40px 40px;
    @media (max-width: $max-resp-width) {
      padding: 60px 15px 22px 15px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
    border: none;

    .ant-modal-title {
      color: #524e59;
      font-size: 24px;
      @media (max-width: $max-resp-width) {
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 26px;
        color: #000;
      }
    }
  }
  .ant-modal-content {
    background-color: #fff;
    color: #7d7a83;
    padding-bottom: 40px;
    @media (max-width: $max-resp-width) {
      background-color: #ebebeb !important;
      color: #7d7a83 !important;
      padding-bottom: 50px !important;
      height: 100vh !important;
      position: fixed !important;
      top: 0px !important;
      left: 0px !important;
      width: 100vw !important;
      padding-top: 0px;
      .ant-modal-close-x {
        visibility: hidden;
        position: fixed;
        left: -15px;
        top: 10px;
        &::after {
          content: "<";
          color: black;
          z-index: 999;
          width: 10px;
          height: 10px;
          visibility: visible;
          font-size: 25px;
          font-weight: 300;
        }
      }
    }

    .ant-modal-body {
      padding: 0px 35px 0 35px !important;
      @media (max-width: $max-resp-width) {
        background-color: #ebebeb;
        padding: 20px 10px !important;
      }
    }
  }
  .ant-modal-footer {
    background-color: white;
    border: none;
    text-align: left;
    padding: 40px;
    padding-bottom: 60px;
    display: none;
  }
  .ant-modal-close {
    display: inline;
    top: 20px;
    .anticon {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ececec;
      border-radius: 100px;
    }
  }
}
.children-cards-container {
  .row {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .d-flex {
    display: flex;
    flex-direction: row;
    align-items: center;

    &.reverse {
      flex-direction: column;
    }
  }
  .j-c-c {
    justify-content: center;
  }
  .j-c-sb {
    justify-content: space-between;
  }
  .j-c-se {
    justify-content: space-evenly;
  }
  .j-c-fs {
    justify-content: flex-start;
  }
  .a-i-fs {
    align-items: flex-start;
  }

  .mt-5 {
    margin-top: 5px;
  }

  .children-cards {
    flex-wrap: wrap;

    .children-card {
      margin-right: 16px;
      margin-bottom: 10px;
      width: 295px;
      padding: 7px 25px;
      padding-bottom: 0px;
      border-radius: 15px;
      border: 1px solid rgba(151, 151, 151, 0.3);
      background: #fff;
      position: relative;
      @media (max-width: $max-resp-width) {
        width: 100%;
        margin-right: 0px;
      }
      .open-report {
        position: absolute;
        top: 10px;
        right: 33px;
      }
      .delete-icon {
        position: absolute;
        right: 10px;
        top: 13px;
      }

      h2 {
        color: #524e59;
        font-size: 16px;
      }

      .wow-pic {
        transform: translate(14px, -20px);
        img {
          width: 60px;
          object-fit: contain;
          height: 50px;
        }
      }

      .purple-text {
        color: $magenta-color;
        font-weight: 500;
        font-size: 16px;

        ~ p {
          font-family: Rubik;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          color: #524e59;
        }
      }
    }
  }
}
