@import "assets/stylesheet/variables.scss";

$grey-color: #524E59;

.add-student-card {
  cursor: pointer;
  width: 560px;
  max-height: 220px;
  padding: 25px;
  border-radius: 8px;
  border: 2px solid white;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
  margin: 20px;
  margin-left: 0px;

  &.active {
    border: 2px solid $magenta-color;
  }

  .student-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .name-image {
      display: flex;
      flex-direction: row;

      img {
        margin-right: 20px;
      }
    }

    .student-image {
      img {
        width: 58px;
        height: 58px;
        object-fit: cover;
        border-radius: 500px;
      }
    }

    .student-name {
      h4 {
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        color: #524E59;
        margin-bottom: 8px;
      }

      p {
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #524E59;
        margin-bottom: 25px;
      }
    }

    .student-grade {
      p {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #D3AEEF;
        padding: 8px 13px;;
        border-radius: 6px;
        margin: 0px;
        width: 128px;

        svg {
          margin-right: 5px;
        }

        span {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #9134D8;
        }
      }
    }
  }

  .student-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .v-divider {
      height: 80px;
      width: 2px;
      background-color: #ECECEC;
    }

    .footer-info {
      padding: 12px;
      //border-right: 2px solid #ECECEC;

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #524E59;

        &.title {
          font-weight: normal;
          color: #524E59;
          opacity: 0.3;
          margin-bottom: 5px;
        }
      }

      &:nth-child(1) {
        //padding-left: 0px;
      }

      &:nth-child(3) {
        // border-right: 0px;
      }
    }
  }
}
