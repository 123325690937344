@import 'assets/stylesheet/variables.scss';

.custom__modal {
  .ant-modal-header {
    display: block;
    background-color: white;
    padding: 40px 40px;
    @media(max-width: $max-resp-width) {
      padding: 60px 15px 22px 15px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
    border: none;

    .ant-modal-title {
      color: #524E59;
      font-size: 24px;
      @media(max-width: $max-resp-width) {
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 26px;
        color: #000;
      }
    }
  }

  .ant-modal-content {
    background-color: #fff;
    color: #7D7A83;
    padding-bottom: 40px;
    @media (max-width: $max-resp-width) {
      background-color: #EBEBEB !important;
      color: #7D7A83 !important;
      padding-bottom: 50px !important;
      height: 100vh !important;
      position: fixed !important;
      top: 0px !important;
      left: 0px !important;
      width: 100vw !important;
      padding-top: 0px;
      .ant-modal-close-x {
        visibility: hidden;
        position: fixed;
        left: -15px;
        top: 10px;

        &::after {
          content: "<";
          color: black;
          z-index: 999;
          width: 10px;
          height: 10px;
          visibility: visible;
          font-size: 25px;
          font-weight: 300;
        }
      }
    }

    .ant-modal-body {
      padding: 0px 35px 0 35px !important;
      @media(max-width: $max-resp-width) {
        background-color: #EBEBEB;
        padding: 20px 10px !important;
      }
    }
  }

  .ant-modal-footer {
    background-color: white;
    border: none;
    text-align: left;
    padding: 40px;
    padding-bottom: 60px;
    display: none;
  }

  .ant-modal-close {
    display: inline;
    top: 20px;

    .anticon {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ECECEC;
      border-radius: 100px;
    }
  }
}
