@import 'assets/stylesheet/variables.scss';

.batch-card {
  display: flex;
  flex-direction: row;
  width: 320px;
  transition: box-shadow 300ms ease-in-out;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
  background: #FFFFFF;
  box-shadow: 2px 0px 34px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin-top: 16px;
  margin-right: 15px;
  color: #000;
  @media(max-width: $max-resp-width) {
    margin: 0px !important;
    margin-bottom: 20px !important;
    width: 100%;
    padding: 0px;
  }

  &.today-batch {
    background: radial-gradient(177.84% 146.55% at 0% 2.69%, #B02DF3 0%, #FF8942 100%);
    border-radius: 6px;
    color: #fff;
  }

  &:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  }

  .card-content {
    padding: 25px 20px;
    flex-grow: 1;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #000;


    &:hover {
      border-color: white;
    }

    .timings {
      color: rgba(82, 78, 89, 1);
      font-size: 15px;
      font-weight: 500;
      display: flex;
      margin-top: 15px;
      align-items: center;
      flex-wrap: wrap;

      svg {
        margin-right: 8px;
      }

      .time-slot {
        padding-left: 10px;
        color: $light-gray-color3;
      }
    }

    .start-time {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;


      .date {
        font-weight: 500;
        color: #F4712B;
        background-color: rgba(244, 113, 43, 0.3);
        border-radius: 5px;
        padding: 3px 12px;
        font-size: 12px;

        &.today-batch {
          background-color: white;
          color: $magenta-color;
        }
      }

      .time {
        display: flex;
        align-items: center;
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;

        svg {
          margin-right: 5px;
        }

        &.today-batch {
          color: white;

          svg path {
            fill: white;
          }
        }
      }
    }

    .grades {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-weight: 500;
      color: $magenta-color;
      font-size: 14px;

      &.today-batch {
        color: $white-color;
      }
    }

    .batch-name {
      cursor: pointer;
      font-size: 20px;
      font-weight: 500;

      &.today-batch {
        color: $white-color;
      }
    }

    .card-footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      margin-top: 30px;
      cursor: pointer;

      .weekday {
        padding: 12px 16px;
        background: #F5F5F5;
        border-radius: 7px;
        margin: 0 10px 10px 0;
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;

        &.today-batch {
          border: 1px solid #FFFFFF;
          box-sizing: border-box;
          border-radius: 7px;
          background: transparent;
          color: white;
        }
      }

      .arrow {
        font-weight: 500;
        color: #525860;

        &.today-batch {
          color: white;
        }
      }
    }

  }
}
