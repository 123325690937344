@import 'assets/stylesheet/variables.scss';

.make-announcement-modal {
  z-index: 9999 !important;

  p {
    margin-top: 20px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .ant-modal-mask {
    background: "rgba(0,0,0,0.45)";
  }

  .ant-input {
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.06);
    background-color: white !important;
    padding: 20px;
  }

  .ant-modal {
    top: 160px;
  }

  .ant-modal-header {
    display: block;
    background-color: white;
    padding: 40px 40px 20px 40px;
    border: none;
    @media(max-width: $max-resp-width) {
      padding: 20px;
      padding-top: 50px;
      padding-bottom: 15px;
    }

    .ant-modal-title {
      color: #524E59;
      font-size: 24px;
    }
  }

  .ant-modal-content {
    background-color: white;
    color: #7D7A83;

    tbody {
      td {
        &:nth-child(2) {
          font-weight: 500;
        }
      }
    }

    .ant-modal-body {
      padding: 0px 35px 18px 35px !important;
      @media(max-width: $max-resp-width) {
        padding: 20px !important;
        padding-top: 0px !important;
      }

      span {
        &.ant-radio {
          display: none;
        }
      }

      .ant-input {
        border-radius: 5px;
        background-color: #F7F7F8;
        border: none;
      }

      .ant-radio-group {
        width: 100%;

        .ant-radio-wrapper {
          font-weight: 500;
          border: 2px solid #7D7A83;
          border-radius: 5px;
          width: 29%;
          text-align: center;

          &:nth-child(1) {
            &.ant-radio-wrapper-checked {
              background-color: #27AE60;
              border-color: #27AE60;
              color: white;
            }
          }

          &:nth-child(2) {
            &.ant-radio-wrapper-checked {
              background-color: #EB5757;
              border-color: #EB5757;
              color: white;
            }
          }

          &:nth-child(3) {
            &.ant-radio-wrapper-checked {
              background-color: #F2BD1D;
              border-color: #F2BD1D;
              color: white;
            }
          }
        }
      }
    }
  }

  .ant-modal-footer {
    background-color: white;
    border: none;
    text-align: left;
    padding: 25px 40px 40px 27px;
    @media(max-width: $max-resp-width) {
      padding: 20px;
      padding-bottom: 50px;
    }

    .ant-btn {
      &:nth-child(1) {
        display: none;
      }
    }

    .ant-btn-primary {
      background-color: $magenta-color;
      font-weight: 500;
      font-size: 12px;
      @media(max-width: $max-resp-width) {
        width: 100%;
        margin: 0px;
        border-radius: 8px;
        font-weight: bold;
        font-size: 16px;
        height: auto;
        padding: 15px 15px;
      }

      &:hover,
      &:focus,
      &:visited {
        background: $honey-flower;
        color: $white-color;
      }
    }
  }

  .ant-modal-close {
    display: inline;
    top: 20px;

    .anticon {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ECECEC;
      border-radius: 100px;
    }
  }

  .ant-table-tbody {
    * {
      border: none;
    }

    &:before {
      content: "@";
      display: block;
      line-height: 10px;
      text-indent: -99999px;
    }

    > tr.ant-table-row-hover {
      td {
        background: white !important;
      }
    }

    > tr {
      &:hover {
        td {
          background: white !important;
        }
      }
    }
  }

  .ant-table-thead {
    * {
      border: none;
      color: #707070;
      font-size: 12px;
      font-family: Rubik;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.83;
      letter-spacing: normal;
      text-align: left;
      color: #524e59;
    }

    > tr.ant-table-row-hover {
      td {
        background: white !important;
      }
    }

    > tr {
      &:hover {
        td {
          background: white !important;
        }
      }
    }
  }

  .card-content {
    height: 450px !important;
    display: none;
  }
}
