.preference-details {
  .primary-btn-pref {
    background-color: #9351cd;
    height: auto;
    width: auto;
    border-radius: 8px;
    padding: 14px 35px;

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      text-transform: capitalize;
      color: #fff;
    }

    &:hover {
      background-color: #9351cd;

    }
  }

  .start-batch {
    margin-top: 50px;
  }

  .admin-right-content {
    position: relative;

    & > {
      .seperator-20 {
        display: none;
      }
    }
  }

  .child-image {
    position: absolute;
    bottom: 0px;
    right: 0px;;
  }

  .preference-info {
    background-color: #fcf2d2;
    width: 100%;
    height: 179px;
    border-radius: 8px;
    margin-top: 24px;
    padding: 0px 24px;
    padding-right: 50px;
    margin-bottom: 20px;

    .days {
      h5 {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;
        color: #000000;
      }

      h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
      }
    }

    .info {
      .info-item {
        margin-left: 91px;

        svg {
          margin-bottom: 12px;
        }

        h5 {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          margin-bottom: 12px;;
        }

        h2 {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: -0.222222px;
          color: #000000;
        }
      }
    }
  }

  .buckets {
    display: flex;
    flex-wrap: wrap;

    .bucket {
      flex-basis: 16%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 18px 16px;
      margin-right: 20px;

      h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        color: #F4712B;
        margin-bottom: 14px;
      }

      .student-images {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;

        img {
          width: 40px;
          height: 40px;
          border-radius: 100%;
          object-fit: cover;
          margin: 0 10px 10px 0;
        }
      }

      .ant-btn {
        width: 100%;
        border: 1px solid #9351CD;

        span {
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 12px;
          text-align: center;
          text-transform: capitalize;
          color: #9351CD;
        }
      }
    }
  }
}
