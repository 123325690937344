@import "assets/stylesheet/variables.scss";

$grey-color: #524e59;
$grey-color-light: #a8a7ac;

.class-details {
  overflow: hidden;
  .detail-row {
    td {
      border: 1px solid rgb(122, 122, 122) !important;
    }
  }

  .mob-buttons {
    display: none;
  }
  h1,
  h2 {
    color: $grey-color;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .detail-row {
    margin-bottom: 30px;
    border-top: 1px solid #ececec;
    padding-top: 30px;
    position: relative;

    @media (max-width: $max-resp-width) {
      &::after {
        width: 120%;
        height: 16px;
        background-color: #e3e3e3;
        content: "z";
        text-indent: -999px;
        position: absolute;
        left: -20px;
        bottom: -38px;
        z-index: 1;
      }
    }

    &:nth-of-type(1) {
      padding-top: 0px;
      border: 0px;
    }

    &:nth-last-child(1) {
      &::after {
        display: none;
      }
    }
  }

  .subject-title {
    .pill {
      margin-left: 15px;
    }
    @media (max-width: $max-resp-width) {
      margin-bottom: 5px;
    }
  }

  .shadow-box {
    box-shadow: 0 4px 34px 0 rgba(0, 0, 0, 0.06);
    margin-bottom: 30px;
    padding: 35px;
    background-color: white;

    h2 {
      margin-bottom: 10px;
    }

    @media (max-width: $max-resp-width) {
      padding-left: 18px;
      padding-right: 18px;
    }
  }

  .h-500 {
    min-height: 500px;
    @media (max-width: $max-resp-width) {
      margin-top: 10px;
    }
  }

  .tab-content {
    color: $grey-color;
    font-size: 16px;
  }

  .back-btn {
    color: $grey-color;
    font-weight: 500;
    border: none;
    padding: none;
    margin: none;
    margin-left: -12px;
    font-size: 16px;
    width: auto;
    height: auto;
    margin-left: 3px;
  }

  .d-flex {
    display: flex;
    flex-direction: row;
    align-items: center;

    &.sb {
      justify-content: space-between;
    }
  }

  .btn-outline-purple {
    border: 2px solid $magenta-color;
    color: $magenta-color;
    font-weight: bold;
    width: auto;
    height: auto;
    padding: 5px 5px;
    border-radius: 5px;
    margin: 0 5px;
  }

  .homework-text {
    font-weight: bold;
    color: #219653;
    margin-left: 20px;
  }

  .class-timings {
    //width: 28%;
    //min-width: 28%;
    //justify-content: space-between;

    h3 {
      margin-right: 20px;
      color: $grey-color !important;
      display: flex !important;
    }

    @media (max-width: $max-resp-width) {
      justify-content: space-between;
      margin-bottom: 24px;
      h3 {
        margin-right: 0px;
      }
    }

    .icon-container {
      padding: 0 5px 0 0;
      transform: translateY(3px);
    }
  }

  .seperator-20 {
    width: 100%;
    height: 20px;
  }

  .description {
    color: $grey-color;

    h5 {
      font-size: 14px;
    }

    p {
      font-weight: 400;
    }
  }
  @media (max-width: $max-resp-width) {
    .mob-buttons {
      display: block;
      margin-top: 40px;
      display: flex;
      & > div {
        width: 100%;
        .ant-btn,
        p {
          flex: 1;
          height: 50px;
          font-weight: 500;
          text-align: center;
          margin-left: 0px;
        }
      }
    }
    .desk-buttons {
      display: none;
    }
  }
  .mobile-page-header {
    h1 {
      color: black;
    }
  }
}
