@import 'assets/stylesheet/variables.scss';
.batch-details {
    transition: all 0ms ease !important;
    .header {
      margin-left: 15px;
    }
    .back-btn {
      color: #524E59;
      font-weight: 500;
      border:none;
      padding: none;
      margin: none;
      margin-left: -12px;
      font-size: 16px;
      width: auto;
      height: auto;
    }
    .container {
        width: 100%;
        display: flex;
        flex-direction: row;

        .main {
            flex-grow: 1;
            background-color: white;
            width: 100px;
            
            .ant-tabs-bar {
                border: none;
            }
            .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
                height: 75px;
            }
            .card-container p {
                margin: 0;
              }
              .card-container > .ant-tabs-card .ant-tabs-content {
                margin-top: -18px;
                margin-left: 5px;
                @media(max-width: $max-resp-width) {
                  margin-top: -5px !important;
                }
              }
              .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
                background: #fff;
              }
              .card-container > .ant-tabs-card > .ant-tabs-nav::before {
                display: none;
              }
              .card-container > .ant-tabs-card .ant-tabs-tab,
              [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
                border-color: transparent;
                background: transparent;
                margin-left: 10px;
                color: #999999;
                font-weight: 500;
                font-size: 16px;

                path, svg {
                  fill: #999999;
                }
              }
              .card-container > .ant-tabs-card .ant-tabs-tab-active,
              [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
                border-color: #fff;
                background: #fff;
                box-shadow: 0px 0px 15px rgba(0,0,0,0.07);
                height: 60px;
                margin: 20px;
                padding-top: 10px;
                padding-bottom: 10px;
                color: black;
                path, svg {
                  fill: #000;

                }
              }
              #components-tabs-demo-card-top .code-box-demo {
                background: #f5f5f5;
                overflow: hidden;
                padding: 24px;
              }
              [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-content {
                height: 120px;
                margin-top: -8px;
              }
              [data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab {
                border-color: transparent;
                background: transparent;
              }
              [data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
                background: #000;
              }
              [data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
                background: #141414;
              }
              [data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
                border-color: #141414;
                background: #141414;
              }
              .ant-tabs-tab {
                z-index: 1;
              }
              @media(max-width: $max-resp-width) {
                .ant-tabs-nav-wrap {
                  height: 48px;
                }
                .card-container {
                  background: rgb(246,246,246);
                  background: linear-gradient(0deg,rgba(255,255,255,1) 10%, rgba(246,246,246,1) 99%, rgba(255,255,255,0) 100%);
                  border-radius: 8px;
                }
                .ant-tabs-nav-container {
                  height: 100% !important;
                }
                .ant-tabs-tab {
                  justify-content: flex-start !important;
                  margin: 0px !important;
                  border-top-left-radius: 8px;
                  border-top-right-radius: 8px;
                  height: 100%;
                  padding: 10px 20px !important;
                  background-color: #F6F6F6 !important;
                  width: 50% !important;

                  p {
                    text-align: left;
                  }
                  &.ant-tabs-tab-active {
                    background-color: white !important;
                    z-index: 2;
                  }
                }
                .ant-tabs-tabpane {
                  &:nth-child(1) {
                    &.ant-tabs-tabpane-active {
                      border-top-right-radius: 15px !important;
                    }
                  }
                  &:nth-child(2) {
                    &.ant-tabs-tabpane-active {
                      border-top-left-radius: 15px !important;
                      box-shadow: rgba(0, 0, 0, 1) !important;
                    }
                  }
                }
                .ant-tabs-card-content {
                  margin-left: 0px !important;
                }
                .ant-tabs-card-content {
                  border-radius: 8px !important;
                }
              }
        }

      .main {
        @media(max-width: $max-resp-width) {
          padding: 0px 15px;
          background-color: #E8E8E8 !important;
        }
      }

      .sidebar {
        padding: 10px;
        width: 400px;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media(max-width: $max-resp-width) {
          background-color: #E8E8E8 !important;
          padding: 0px 15px !important;
          .announcement-card {
            width: 100%;
          }
        }
      }
    }

}
