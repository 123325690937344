.component-activities {
  .activities {
    padding: 96px 30px 0 30px;
    .activity-banner-container {
      cursor: pointer;
    }
    .heading {
      font-size: 20px;
      font-weight: bold;
      color: #1e1e1e;
      margin-bottom: 16px;
      @media screen and (max-width: 768px) {
        text-align: center;
      }
    }
    .individual-activities {
      display: flex;
      flex-wrap: wrap;
      @media screen and (max-width: 768px) {
        >div {
          margin: auto;
        }
      }
    }
  }
}
.activity-modal {
  width: 52vw !important;
  height: 661px !important;
  @media screen and (max-width: 1023px) {
    width: 100vw !important;
    height: 100vh !important;
    margin: 0;
    padding: 0;
  }
  .ant-modal-content {
    height: 100%;
    .ant-modal-close-x {
      color: #212121;
      svg {
        height: 20px;
        width: 20px;
      }
      @media screen and (max-width: 1023px) {
        display: none;
      }
    }

  }
}
.ant-modal-body {
  padding: 0px !important;
  height: 100%;
}

.success-modal {
  width: 100vw !important;
  height: 100vh !important;
  top: 0;
}