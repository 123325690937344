.grade-card {
  &__container {
    width: 355px;
    border-radius: 8px;
    box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.06);
    border: solid 0 #979797;
    background-color: #ffffff;
    padding: 24px;
    position: relative;

    &--ant-switch {
      position: absolute !important;
      top: 24px;
      right: 24px;
    }
  }


  &__mid-section {
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    &--left {
      padding-right: 16px;
      border-right: 1px solid #524e59;
    }

    &--right {
      padding-left: 13px;
    }

    &--sub-text {
      font-family: 'Rubik';
      font-size: 16px;
      font-weight: normal;
      line-height: normal;
      text-align: left;
      color: rgba(82, 78, 89, 0.4);
    }

    &--text {
      font-family: 'Rubik';
      font-size: 16px;
      font-weight: normal;
      line-height: normal;
      text-align: left;
      color: #524e59;
    }
  }

  &__thumbnail {
    width: 54px;
    height: 54px;
    object-fit: contain;
    border-radius: 50%;
    margin-bottom: 10px;
  }

  &__title {
    font-family: 'Rubik';
    font-size: 28px;
    font-weight: 500;
    line-height: normal;
    color: #524e59;
    margin-bottom: 8px;
  }
}

.ant-switch-checked {
  background-color: #c37cf3;
}

.edit-icon-container {
  position: absolute;
  right: 35px;
  top: 95px;
  cursor: pointer;
}
