@import 'assets/stylesheet/variables.scss';
.view-all-announcement-modal {
    .ant-modal-footer {
        display: none !important;
    }
    .announcement-card {
        width: 100%;
        margin: none;

        .card-content {
            height: 500px;
            overflow-y: auto;

        }
    }
    &.no-scroll {
        .card-content {
            overflow-y: hidden;
            height: 550px;
        }
    }
    @media(max-width: $max-resp-width) {
        /*.ant-modal-close {
            position: absolute;
            left: 0px;
            top: 0px;
            right: auto;
            bottom: auto;
            visibility: hidden;

            &::after {
                content: "<";
                width: 10px;
                height: 10px;
                visibility: visible;
            }
        }*/
    }
}
