@import 'assets/stylesheet/variables.scss';

.storycard-container {
  width: 232px;
  height: 276px;
  // background-image: linear-gradient(359deg, #552d83, #432870 64%, #361e5f);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 4px;
  margin-right: 20px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  .read-time {
    position: absolute;
    border-radius: 14px;
    background-color: #ffffff;
    color: $theme-color;
    right: 14px;
    top: 10px;
    font-size: 12px;
    padding: 2.5px 6px 2px 6px;
    display: flex;
    // width: 52px;
    height: 20px;
  }
  .bottom-div {
    justify-content: space-between;
    display: flex;
    .title {
      display: flex;
      flex-direction: column;
      font-size: 18px;
      font-weight: 500;
      color: #fff;
      display: flex;
      align-items: flex-start;
      max-height: 64px;
      .story-name {
        flex: 0.9;
        padding-right: 6px;
        line-height: 1.1;
        margin-right: 40px;
      }
      .bookmark-icon {
        flex: 0.1;
        margin-top: 6px;
      }
    }
  }
  .story-author,
  .story-publisher {
    color: #fff;
    font-size: 12px;
    opacity: 0.8;
  }
  .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
    padding-left: 0px;
    li {
      border-radius: 4px;
    }
  }
  .story-data {
    background: linear-gradient(to top, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
    padding: 20px 16px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .story-description {
      color: rgba($color: #ffffff, $alpha: 0.8);
    }
  }
}
