@import "assets/stylesheet/variables.scss";

.pref-groups {
  .preference-groups-container {
    max-height: 50vh;
    overflow-y: scroll;

    .card-container {
      display: flex;
      flex-wrap: wrap;

      .pref-group-card {
        display: flex;
        overflow: hidden;
        flex-basis: 31%;
        //height: 152px;
        height: auto;
        margin: 8px 16px 8px 0;
        padding: 24px 6.5px 0 32px;
        border-radius: 16px;
        border: solid 2px rgba(82, 78, 89, 0.1);
        background-color: #ffffff;
        cursor: pointer;

        &.active {
          border: solid 3px #9134d8;

        }

        @media(max-width: $max-resp-width) {
          display: flex;
          overflow: hidden;
          height: 152px;
          margin: 8px;
          border-radius: 16px;
          border: solid 2px rgba(82, 78, 89, 0.1);
          background-color: #ffffff;
          cursor: pointer;
          width: 156px;
          padding: 16px;
          padding-top: 26px;
          flex-basis: 45%;
        }

        .content {
          width: 90%;

          .heading {
            flex: 1;
            height: 17px;
            font-family: Rubik;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.19px;
            text-align: left;
            color: #524e59;
            position: sticky;
            position: -webkit-sticky;
            z-index: 50;
            background: #fff;
            display: flex;
            justify-content: space-between;

            .start-date {
              font-style: italic;
              color: #979797;
              text-align: right;
              font-weight: 400;

              &:before {
                content: ' (';
              }

              &:after {
                content: ')';
              }
            }
          }

        }

        .time-slots-section {
          display: flex;
          flex-direction: column;
          margin: 10px 0;
          overflow-y: auto;

          .time-slot {
            flex: 1;
            height: 14px;
            opacity: 0.6;
            padding: 4px 0;
            font-family: Rubik;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.19px;
            text-align: left;
            color: #524e59;

            .day-name {
              font-weight: bold;
              display: inline;
            }

            .day-name::after {
              content: '';
              padding: 0 3px 0 0;
            }
          }
        }

      }
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;

    .ant-btn {
      font-size: 12px;
      color: #524E59;
      height: 50px;
      width: 13%;
      background: white;
      border: 1px solid #524E59;
      font-weight: bold;
      border-radius: 10px;
      margin-right: 10px;
    }

    .ant-btn-primary {
      background-color: $magenta-color;
      font-weight: 500;
      font-size: 12px;
      border: 0px;
      color: white;
      width: 10%;
    }

    .ant-btn-primary:hover {
      background: $honey-flower;
      border-radius: 8px;
      color: $white-color;
      border: none;
      outline: none;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .ant-btn-primary[disabled] {
      &:hover {
        background-color: $magenta-color;
      }
    }
  }


}
