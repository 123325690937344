@import 'assets/stylesheet/variables.scss';

.login-container {
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(359deg, #552d83, #432870 64%, #361e5f);
  display: flex;
  align-items: center;
  overflow-y: auto;
  .left-part{
    text-align: center;
    padding: 0 8%;
    flex: 0.55;
    .user-illustration {
      margin-bottom: -90px;
      user-select: none;
      -webkit-user-drag: none;
    }
    .title {
      font-size: 37px;
      font-weight: 500;
      color: #ffffff;
      margin-top: 24px;
    }
    .gradient-text {
      background-image: radial-gradient(circle at 50% 3%, #e6b815, #ff8a23);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      object-fit: contain;
      font-size: 68px;
      font-weight: 500;
    }
    .helper-text {
      font-size: 16px;
      color: #ffffff;
      opacity: 0.8;
    }
  }
  .right-part {
    .mobile-logo {
      display: none;
    }
    flex: 0.45;
    padding: 0 96px;
    .login-card {
      border-radius: 6px;
      box-shadow: 0 22px 124px 0 rgba(0, 0, 0, 0.22);
      background-color: #ffffff;
      padding: 80px 56px;
      .heading {
        font-size: 32px;
        font-weight: 500;
      }
      .sub-heading {
        opacity: 0.5;
        font-size: 20px;
        color: #181e3b;
        margin-bottom: 48px;
      }
      button {
        background-color: $theme-btn-color;
        color: #fff;
        height: 52px;
        font-size: 16px;
        border: none;
        margin-top: 16px;
        font-weight: 500;
        border-radius: 6px;
        margin-right: 16px;
      }
      button:disabled {
        background-color: $theme-btn-color-disabled;
      }
      a{
        color: $theme-color;
        font-weight: 600;
        cursor: pointer;
      }
      .label {
        font-size: 16px;
        margin-bottom: 8px;
        font-weight: 400;
      }
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1272px) {
    .right-part {
      padding: 0 24px;
      // flex: 0.55;
      .login-card {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

  }
  @media screen and (max-width: 1024px) {
    background-image: $theme-gradient;
    .left-part {
      display: none;
    }
    .input-focused {
      margin-top: 100px;
    }
    .right-part {
      width: 100%;
      padding: 0;
      flex: 1;
      .mobile-logo {
        display: block;
        position: absolute;
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
      }
      .login-card {
        padding: 0px 40px;
        background: transparent;
        box-shadow: none;
        color: #fff;
        .ant-form {
          color: #fff;
        }
        .sub-heading {
          color: #fff;
          opacity: 1;
        }
        a {
          color: #fff;
        }
        input {
          color: rgba(0, 0, 0, 0.65);
        }
      }
    }
  }
}