@import "assets/stylesheet/variables.scss";

$grey-color: #524e59;

.add-teacher-header {
  padding: 0;

  &__back-btn {
    color: $grey-color;
    //font-weight: 500;
    border: none;
    margin: none;
    margin-left: -12px;
    font-size: 16px;
    width: auto;
    height: auto;
    padding: 0 !important;
    margin-left: 3px;
    text-transform: uppercase !important;
  }

  h1 {
    line-height: 36px;
    font-size: 24px;
    margin: 0;
    padding: 5px 0 7px 0;
    color: #000;
    font-weight: 500;
  }
}

.add-teacher-wrapper {
  border-radius: 6px;
  //box-shadow: 1px 1px 1px 0 rgba(44, 11, 74, 0.15);
  background-color: $white-color;
  padding: 15px 0;

  .separator-line {
    background: $light-gray-color;
    height: 2px;
    margin: 8px 0;
  }

  .heading1 {
    font-size: 20px;
    color: $dark-magenta;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 8px;
  }

  .work-timings {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 12px;

    .timeslots-error {
      color: $flamingo;
      padding-left: 15px;
    }

    .day-name {
      font-size: 16px;
      font-weight: 500;
      color: $default-text-color;
      text-transform: uppercase;
    }

    .button {
      display: flex;
      align-items: center;
      font-size: 24px;
      color: $honey-flower;
      text-align: center;
      font-weight: 500;
      z-index: 1;
      cursor: pointer;

      p {
        font-size: 16px;
        color: $honey-flower;
        padding: 1px 0 0 20px;
      }

      svg {
        transform: translateY(-1px);
      }
    }
  }

  .previous-work {
    .add-another {
      display: flex;
      align-items: center;
      line-height: 47px;
      font-size: 29px;
      color: $honey-flower;
      text-align: center;
      font-weight: 500;
      z-index: 1;
      cursor: pointer;

      p {
        font-size: 16px;
        color: $honey-flower;
        padding: 5px 0 0 10px;
      }
    }
  }
}


.teacher-form {
  .ant-form-item-label {
    line-height: normal;
  }

  .ant-form-item .ant-form-item-label label,
  .custom-form-label {
    position: relative;
    padding-bottom: 8px;
    min-height: 22px;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    color: $light-gray-color3;
    text-transform: uppercase;

    &.no-uppercase {
      text-transform: unset;
    }

    &:after {
      display: none;
    }
  }

  .ant-form-item {
    margin-bottom: 1px;
  }

  .ant-input,
  .ant-input:focus,
  .ant-input:hover,
  .ant-input:active,
  .ant-select-selection,
  .ant-input-number {
    background: rgba(236, 236, 236, 0.2) !important;
    border-radius: 8px;
    height: 56px;
    //opacity: 0.2;
    border: none;
    box-shadow: none !important;
    font-size: 20px;
    font-weight: 500;
    color: $default-text-color;
    outline: none;
    padding: 0 16px;
    width: 96.5% !important;
  }

  .ant-select-selection__placeholder,
  textarea::placeholder,
  input::placeholder {
    font-size: 16px;
    color: $default-text-color;
    opacity: 0.2;
    font-weight: 500;
  }

  .ant-input-group-addon {
    background: $white-color !important;
    border: none;
    border-radius: 20px 0 0 20px;
    font-size: 20px;
    font-weight: 500;
    color: $default-text-color;
    padding-right: 0;
  }

  .ant-input-group-addon + .ant-input,
  .ant-input-group-addon + .ant-input:hover,
  .ant-input-group-addon + .ant-input:focus {
    border-radius: 0 20px 20px 0;
    padding-left: 4px;
    background: $white-color !important;

  }

  .ant-calendar-picker {
    width: 97.3% !important;

    svg {
      transform: translateX(-18px) !important;
    }
  }

  .ant-select-selection__rendered,
  .ant-input-number-input-wrap {
    padding: 0;
    margin: 0;
    line-height: 56px;
  }

  .ant-input-number-input {
    padding: 0;
    font-size: 20px;
    font-weight: 500;
    color: $default-text-color;
  }

  .ant-select-selection-selected-value {
    line-height: 56px;
  }

  textarea.ant-input,
  textarea.ant-input:hover,
  textarea.ant-input:focus,
  textarea.ant-input:active,
  textarea.ant-input:visited {
    min-height: 158px !important;
    padding: 10px 16px;
  }

  .ant-form-item-children {
    display: block;
  }

  .ant-btn,
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:visited,
  .ant-btn:active {
    background: $honey-flower;
    border-radius: 8px;
    height: 56px;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    color: $white-color;
    width: 100%;
    border: none;
    outline: none;
    margin-top: 30px;
  }

  .ant-btn:disabled {
    opacity: 0.5;
  }

  .ant-checkbox-wrapper {
    min-height: 15px;
    margin: 8px 0 0 0;
    line-height: normal;
    font-size: 16px;
    font-weight: 500;
    color: $default-text-color;
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 21% 0 0;
  }

  .ant-form-item-children {
    line-height: normal;
  }

  .ant-checkbox:after {
    display: none;
  }

  .ant-checkbox-inner,
  .ant-checkbox-inner:hover {
    height: 24px;
    width: 24px;
    box-sizing: border-box;
    border: solid 2px;
    border-color: $magenta-color !important;
    border-radius: 6px;
    margin-right: 10px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $magenta-color;

    &:after {
      left: 30%;
      border-width: 1px;
    }
  }

  .ant-input-number {
    //background-color: rebeccapurple !important;
    height: unset;
  }

  .ant-select-selection__rendered {
    ul {
      li {
        margin-top: 9px;
        line-height: 13px;

        &.ant-select-selection__choice {
          border: none;
          background-color: transparent;
          padding: 0;
          align-items: center;

          .ant-select-selection__choice__content {
            overflow: unset;
            line-height: 24px;
          }

          .ant-select-selection__choice__remove {
            display: none;
          }

        }

        &.ant-select-selection__choice:not(.ant-select-selection__choice:nth-last-of-type(2)):after {
          content: ",";
          font-size: 20px;
          bottom: 10px;
        }
      }
    }
  }

  .ant-select-arrow {
    top: 29px;
    right: 39px;

    svg {
      fill: #525860;
      font-size: 13px;
      font-weight: 500;
    }
  }
}

.ant-select-dropdown {
  &.multi-select-dropdown {
    width: 100%;
  }

  .ant-select-dropdown-menu-item {
    align-items: center;
    display: flex;
    font-size: 20px;
    font-weight: 500;
    color: #524e59;
    padding: 8px 0 8px 50px;

    .dropdown-check-mark {
      height: 23px;
      width: 23px;
      border: solid 2px $magenta-color;
      border-radius: 50px;
      position: absolute;
      left: 15px;

      &.active {
        background: url('../../../../assets/image/circle-check.svg') no-repeat -1px -1px;
        background-size: 23px;
      }
    }

    &:hover {
      &:not(.ant-select-dropdown-menu-item-disabled) {
        background-color: $magenta-color;
        color: #fff;
      }

      .dropdown-check-mark {
        border: solid 2px #fff;

        &.active {
          background-color: #fff;
          background-size: 23px;
        }
      }

    }
  }
}

.grade-form-section {
  position: relative;


}

.add-grade-row {
  height: 47px;
  width: 47px;
  background: $light-gray-color4;
  border-radius: 25px;
  display: inline-block;
  line-height: 47px;
  font-size: 29px;
  color: $honey-flower;
  text-align: center;
  font-weight: 500;
  bottom: 31px;
  right: -555px;
  z-index: 1;
  cursor: pointer;
}

.custom-dropdown {
  background-color: $white-color !important;
  border-radius: 0 !important;
  border: none;
  padding: 0;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.06) !important;

  &:before {
    display: none;
    content: "";
    position: absolute;
    bottom: -20px;
    left: 0;
    background: $light-gray-color4;
    width: 100%;
    height: 20px;
  }

  &.ant-select-dropdown-placement-bottomLeft {
    border-radius: 0 0 20px 20px;

    &:before {
      top: -20px;
      bottom: auto;
    }
  }

  .ant-select-dropdown-menu {
    overflow-x: hidden;
  }

  .ant-select-dropdown-menu-item {
    font-size: 20px;
    font-weight: 500;
    color: $default-text-color;
    padding: 10px 16px;

    &:hover,
    &:active,
    &:focus,
    &-active {
      background-color: $magenta-color;
      color: $white-color;
    }
  }
}

.multi-select {
  .dropdown-heading {
    background-color: rgba(236, 236, 236, 0.2) !important;
    border-radius: 8px !important;
    height: 56px !important;
    border: none !important;
    font-size: 20px;
    font-weight: 500;
    color: $default-text-color !important;
    outline: none !important;
    padding: 0 16px;
    margin-right: 16px;
    width: 96.5% !important;
  }

  .dropdown-heading-value {
    line-height: 56px !important;
    padding: 0 16px !important;
    font-size: 16px;

  }

  .dropdown-content {
    background-color: #fff !important;
    border-radius: 0 0 20px 20px !important;
    border: none !important;
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.06) !important;
    padding: 0 16px;
    overflow: visible !important;
    height: 32vh;
    overflow-y: auto !important;

    &:before,
    &:after {
      display: none;
      content: "";
      position: absolute;
      height: 13px;
      width: 10px;
      left: 0;
      top: -13px;
      background-color: #f0eff1 !important;
    }

    &:after {
      left: auto;
      right: 0;
    }

    .select-item {
      padding: 4px 0 !important;
      cursor: pointer;
    }

    .item-renderer {
      position: relative;
      background: transparent;
      pointer-events: none;

      &:before {
        content: "";
        height: 23px;
        width: 23px;
        box-sizing: border-box;
        border: solid 2px #9134d8;
        display: inline-block;
        border-radius: 50px;
        left: 3px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
      }

      input {
        display: none;
      }

      span {
        position: relative;
        padding: 2px 40px !important;
        color: $default-text-color;
        font-size: 20px;
        font-weight: 500;
        line-height: normal;
      }

      input:checked + span {
        background: transparent url("../../../../assets/image/Select_Active.svg") no-repeat;
        background-position: 3px 2px;
      }
    }

    li {
      cursor: pointer;

      :hover {
        background: $magenta-color !important;

        .item-renderer {
          background: transparent;

          span {
            color: $white-color;
          }

          &:before {
            border: solid 2px $white-color;
            height: 24px;
            width: 24px;
          }

        }
      }
    }

    .select-item {
      background-color: transparent !important;
    }
  }
}

.weekday-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;

  li {
    cursor: pointer;
    height: 56px;
    width: 88px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #979797;
    font-size: 16px;
    font-weight: 500;
    border: none;
    border-radius: 8px;
    margin: 0 16px 0 0;
    background: rgba(236, 236, 236, 0.2);

    &.active {
      background: $magenta-color;
      color: $white-color;
      //border: solid 2px $magenta-color;
    }
  }
}
