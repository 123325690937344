@import "assets/stylesheet/variables.scss";

$shadowType1: 0px 0px 14px rgba(0, 0, 0, 0.10);
.teacher-listing-header {
  .ant-input {
    box-shadow: $shadowType1;
    border: none;

    &:hover {
      box-shadow: $shadowType1;
      border: none;
    }

    &:focus {
      box-shadow: $shadowType1;
      border: none;
    }
  }

  .ant-dropdown-trigger {
    box-shadow: $shadowType1;
    border: none;
  }

}

.batches-listing {
  height: 80vh;
  margin-top: 30px;

  .today-batch-section {
    margin-bottom: 50px;
  }

  .batch-section-heading {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #524E59;
  }

  .cards-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-bottom: 40px;

    .empty-icon {
      background: yellow;
    }

  }

  .center-align {
    display: flex;
    justify-content: center;
    width: 100%
  }
}
