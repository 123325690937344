@import "assets/stylesheet/variables.scss";
$shadowType1: 0px 0px 14px rgba(0, 0, 0, 0.1);

.edit-student-details {
  .export-btn {
    background-color: $magenta-color;
    border-radius: 10px;
    box-shadow: $shadowType1;
  }
  .grey-btn {
    background: #ececec;
    border-radius: 10px;
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      /* identical to box height */

      text-align: center;
      text-transform: capitalize;

      /* Text Grey */

      color: #524e59;
    }
  }
  .shadow-box-container {
    min-height: 100%;
    min-width: 100%;
    background: #ffffff;
    box-shadow: 0px 5px 24px 1px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    display: flex;
  }
}
