@import "assets/stylesheet/variables.scss";

.teacherselection {
  width: 400px !important;
  padding: 8px 0px 8px 5px !important;
}

$shadowType1: 0px 0px 14px rgba(0, 0, 0, 0.1);
.datepicker .ant-input,
.ant-input:focus,
.ant-input:hover,
.ant-input:active,
.ant-select-selection,
.ant-input-number {
  width: unset !important;
}
.preference-listing-section {
  border-radius: 6px 6px 0 0;
  background-color: $white-color;
  height: 80vh;
  position: relative;

  .ant-table-thead {
    background: $athens-gray;

    tr {
      border-radius: 8px !important;
    }

    th {
      font-size: 12px;
      color: #524e59;
      font-weight: 500;
      text-transform: uppercase;
      padding: 12px 10px;
      border-bottom: none;
      text-align: center;
    }

    th:first-of-type {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    th:last-of-type {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .ant-table-placeholder {
    border-top: none;
    border-bottom: none;
    background: transparent;
  }

  .ant-table .ant-table-tbody {
    > tr {
      cursor: pointer;

      > td {
        vertical-align: middle;
        border-bottom: none;
        text-align: center;
      }
    }

    > tr:hover {
      box-shadow: 1px 4px 15px 1px rgba(0, 0, 0, 0.11);
      border-radius: 8px;

      > td {
        background: white !important;
      }

      td:first-of-type {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      td:last-of-type {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    &:before {
      content: "@";
      display: block;
      line-height: 10px;
      text-indent: -99999px;
    }
  }

  .ant-pagination {
    padding: 16px;
    text-align: right;
  }
}

.filter-count {
  display: flex;
  align-items: center;

  span {
    width: 22px;
    height: 22px;
    font-size: 12px;
    background-color: #9134d8;
    border-radius: 100px;
    margin-left: 20px;
    color: white;
    display: inline-block;
    text-align: center;
  }
}

.preference-listing {
  &-header {
    display: flex;
    flex-direction: row;

    .ant-input {
      width: 290px;
      height: 48px;
      font-size: 16px;
      color: $default-text-color;
      border-radius: 8px;
      background: $white-color url("../../../../assets/image/search.svg")
        no-repeat 14px;
      border: solid 1px $iron-color;
      padding-left: 50px;
      outline: none;

      &:hover,
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .ant-dropdown-trigger {
      width: 180px;
      height: 48px;
      font-size: 16px;
      line-height: 48px;
      color: $default-text-color;
      border: solid 1px $iron-color;
      border-radius: 8px;
      background: $white-color;
      padding: 0 16px;
    }

    .export-btn {
      background-color: $magenta-color;
      border-radius: 10px;
      box-shadow: $shadowType1;
      width: 148px;
    }

    .ant-collapse {
      background-color: transparent;
      border: none;

      &-content {
        border: none;
        max-height: 40vh;
        overflow-y: auto;
      }

      &-content-box {
        padding: 10px 0 0 0;
      }

      > .ant-collapse-item {
        border: none;

        > .ant-collapse-header {
          padding: 12px 0;
          font-size: 20px;
          color: $default-text-color;
          font-weight: 500;
          border-bottom: solid 1px $iron-color;
          border-radius: 0;
        }
      }

      > .ant-collapse-item:last-child {
        > .ant-collapse-header {
          border-bottom: none;
        }
      }
    }

    .ant-checkbox-wrapper {
      min-height: 15px;
      margin: 0;
      padding: 6px;
      line-height: normal;
      font-size: 16px;
      color: $default-text-color;
      width: 100%;
    }

    .ant-checkbox:after {
      display: none;
    }

    .ant-checkbox-inner,
    .ant-checkbox-inner:hover {
      height: 20px;
      width: 20px;
      box-sizing: border-box;
      border: solid 2px;
      border-color: $magenta-color !important;
      border-radius: 6px;
      margin-right: 6px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $magenta-color;

      &:after {
        left: 28%;
        border-width: 1px;
        top: 45%;
      }
    }
  }
}

.listing-row {
  &-profile-pic {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    background: $light-gray-color3;
    display: inline-block;
    margin: 0;
    overflow: hidden;
    vertical-align: middle;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    img {
      height: 100%;
    }
  }

  &-primary-text {
    font-size: 16px;
    font-weight: 500;
    color: $default-text-color;
    margin: 3px 0;
    // text-transform: capitalize;
    .weekday {
      padding: 0 4px;
    }
  }

  &-secondary-text {
    font-size: 14px;
    color: $default-text-color;
    margin: 3px 0;
  }

  &-status-tag {
    border-radius: 6px;
    line-height: 20px;
    padding: 2.5px 10px;
    font-size: 12px;
    height: 25px;
    font-weight: 500;
    color: $white-color;
    margin: 0 3px;
    display: inline-block;
    text-transform: uppercase;

    &.inprogress {
      background: #f2bd1d;
    }

    &.notstarted {
      background: #eb5757;
    }

    &.filled {
      background: #219653;
    }
  }

  &-status {
    font-size: 0;
    width: 36px;
    height: 14px;
    border-radius: 10px;
    background: $light-gray-color3;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    position: relative;

    &:after {
      content: "";
      width: 21px;
      height: 21px;
      box-shadow: 0 1px 1.9px 0 rgba(0, 0, 0, 0.25);
      background: $white-color;
      position: absolute;
      top: -4px;
      left: -2px;
      border-radius: 11px;
    }

    &--active {
      background: #e9d6f7;

      &:after {
        background-color: #9134d8;
        right: -2px;
        left: auto;
      }
    }
  }
}

#teacherListingFilter {
  position: relative;
  margin: 0 16px 0 8px;
  cursor: pointer;
}

.ant-btn {
  text-transform: unset;
}

.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary:active,
.ant-btn-primary:visited {
  background-color: $honey-flower;
  border: none;
  min-width: 130px;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.ant-btn-md,
.ant-btn-md:hover,
.ant-btn-md:focus,
.ant-btn-md:active,
.ant-btn-md:visited {
  height: 48px;
}

.ant-btn-default,
.ant-btn-default:hover,
.ant-btn-default:focus,
.ant-btn-default:active,
.ant-btn-default:visited {
  background-color: $light-gray-color;
  border: none;
  min-width: 130px;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  color: $default-text-color;
}
.ant-dropdown-menu {
  max-height: 250px;
  overflow: auto;
}

// New CSS for update modal
.ant-checkbox-wrapper {
  min-height: 15px;
  margin: 0;
  padding: 6px;
  line-height: normal;
  font-size: 16px;
  color: $default-text-color;
  width: 100%;
}

.ant-checkbox:after {
  display: none;
}

.ant-checkbox-inner,
.ant-checkbox-inner:hover {
  height: 20px;
  width: 20px;
  box-sizing: border-box;
  border: solid 2px;
  border-color: $magenta-color !important;
  border-radius: 6px;
  margin-right: 6px;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: $magenta-color !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $magenta-color;

  &:after {
    left: 28%;
    border-width: 1px;
    top: 45%;
  }
}

// .action-dropdown {
//   position: absolute;
//   z-index: 99;
//   top: 12px;
//   right: 10px;
// }

.action-dropdown-button {
  width: 90px;
  height: 35px;
  font-size: 12px;
  line-height: 35px;
  color: #524e59;
  font-weight: 500;
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.45);
}

.ant-modal-content {
  background-color: white;
  overflow: auto;
  max-height: 375px;
}
// .update-modal{
//   .ant-modal-footer {
//     display: none;
//   }
// }

// .ant-modal-body {
//   height: 250px;
//   max-height: 300px;
// }

.update-modal-maindiv-heading {
  display: flex;
  justify-content: space-between;
}

.update-heading--button {
  display: flex;
  align-items: center;
}

.update-modal-heading {
  font-size: 24px;
  font-weight: 500;
  font-family: "Rubik";
  font-style: normal;
  line-height: 33px;
}

.update-dropdown-maindiv {
  display: flex;
  align-items: center;
}

.update-add-min-icon {
  background-color: #9134d8;
  width: 25px;
  height: 25px;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 18px;
  margin-left: 15px;
}

.action-dropdown-button:hover,
.action-dropdown-button:focus {
  border-color: #9134d8 !important;
  color: #9134d8 !important;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  color: #9134d8;
  background-color: transparent;
}

.submit-button-maindiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // float: right;
  // margin-bottom: 20px;
}

.property-values-maindiv {
  height: 240px;
  overflow: auto;
}

.ant-calendar-picker {
  width: 85% !important;
}

.ant-input,
.ant-input:focus,
.ant-input:hover,
.ant-input:active,
.ant-select-selection,
.ant-input-number {
  background: rgba(236, 236, 236, 0.2) !important;
  border-radius: 8px;
  height: 56px;
  //opacity: 0.2;
  border: none;
  box-shadow: none;
  font-size: 20px;
  font-weight: 500;
  color: $default-text-color;
  outline: none;
  padding: 0 16px;
  width: 100%;
}

.ant-select-selection__rendered,
.ant-input-number-input-wrap {
  padding: 0;
  margin: 0;
  line-height: 56px;
}

.ant-select-selection-selected-value {
  line-height: 56px;
}

.ant-select {
  width: 85%;
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: transparent;
  box-shadow: 0 0 0 2px transparent;
}

.ant-time-picker-input:hover {
  border-color: transparent;
  box-shadow: 0 0 0 2px transparent;
}

.ant-time-picker-input {
  background: rgba(236, 236, 236, 0.2) !important;
  border-radius: 8px;
  height: 56px;
  //opacity: 0.2;
  border: none;
  box-shadow: none;
  font-size: 14px;
  font-weight: 500;
  color: $default-text-color;
  outline: none;
  padding: 0 16px;
  width: 100%;
}

.error-div {
  // position: relative;
  // bottom: 20px;
  // display: inline-block;
  color: red;
}

.ant-select-auto-complete.ant-select .ant-select-selection--single {
  height: 56px;
}
.datepicker {
  width: 250px !important;
}
.ant-time-picker-input {
  height: 47px;
}

.ant-input-number-input:placeholder-shown {
  font-size: 16px;
    color: #524e59;
    opacity: 0.2;
    font-weight: 500;
}


