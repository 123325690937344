@import "assets/stylesheet/variables.scss";

.lms-header {
  &__view-switcher {
    display: flex;
    align-items: center;
    background-color: #e9d6f7;
    border-radius: 8px;

    &--switch-text {
      font-family: 'Rubik';
      font-size: 20px;
      font-weight: 500;
      color: rgba(82, 78, 89, 0.3);
      padding: 13px 17px;
      border: solid 0 #979797;
      border-radius: 8px;
      cursor: pointer;
      transition: all .3s;

      &.active {
        box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.09);
        background-color: #ffffff;
        color: #524e59;
      }
    }
  }
}

.view-lms {
  height: 90%;

  .grades-listing-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: -16px;
    margin-left: -16px;

    &__add-grade-container {
      width: 115px;
      height: 107px;
      border-radius: 8px;
      border: dashed 1px #979797;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 32px;
      // margin-left: 18px;
      cursor: pointer;

      &--text {
        font-family: 'Rubik';
        font-size: 12px;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0.89px;
        color: #524e59;
        margin-top: 12px;
      }
    }

    .grade-card__container {
      margin-top: 16px;
      margin-left: 16px;
    }
  }

  .grades-listing-container {
    &__next-button {
      margin-left: 15px;
      cursor: pointer;
    }

    &__prev-button {
      margin-right: 15px;
      cursor: pointer;
    }
  }

  .sessions-listing-section {

    &__header-container {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      border-radius: 8px;
      background-color: #fafafa;
      padding: 16px;

      &--grades-listing {
        display: flex;
        align-items: center;
        width: 100%;
        overflow-x: auto;
        scroll-behavior: smooth;

      }

      &--grades-listing-container {
        margin-right: 15px;
        width: 70%;
        display: flex;
        align-items: baseline;

        ::-webkit-scrollbar {
          display: none;
        }
      }

      &--grades-text {
        font-family: 'Rubik';
        font-size: 16px;
        font-weight: bold;
        line-height: 0.63;
        padding: 16px 0;
        margin: 0 10px;
        white-space: nowrap;
        color: rgba(82, 78, 89, .4);
        cursor: pointer;

        &:first-child {
          margin-left: unset;
        }

        &.active {
          border-bottom: 3px solid #9134d8;
          color: #9134d8;
          padding: 16px 0 13px;
        }
      }
    }

    .button-primary {
      margin-left: 8px;
      width: 145px;
      height: 40px;
      border-radius: 8px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.11);
      border: solid 0 #979797;
      background-color: #9134d8;
      font-family: 'Rubik';
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
      color: #ffffff;
      cursor: pointer;

      &:hover,
      &:focus,
      &:visited {
        background: $honey-flower;
        border-radius: 8px;
        color: $white-color;
        border: none;
        outline: none;
      }
    }


    .sessions-table {
      &__number-col-container {
        display: flex;
        align-items: center;
      }

      &__status-col-container {
        display: flex;
        align-items: center;

        &--edit-icon {
          margin-left: 62px;
        }
      }

      &__text {
        font-family: 'Rubik';
        font-size: 16px;
        font-weight: normal;
        line-height: normal;
        color: #524e59;

        &--bold {
          font-family: 'Rubik';
          font-size: 16px;
          font-weight: 500;
          line-height: normal;
          color: #524e59;
        }
      }
    }

    .ant-table-thead {
      background: $athens-gray;

      tr {
        th {
          font-size: 12px !important;
          color: $light-gray-color3 !important;
          font-weight: 500 !important;
          text-transform: uppercase !important;
          padding: 16px 10px !important;
          background: #fafafa;
        }

        th:nth-of-type(1) {
          width: 5%;
        }

        th:nth-of-type(2) {
          width: 13%;
        }

        th:nth-of-type(3) {
          width: 100%;
        }
      }
    }

    .ant-table .ant-table-tbody {
      > tr {
        cursor: pointer;

        > td {
          vertical-align: middle;
          border-bottom: none;
          //width: max-content;
        }
      }

      > tr:hover {
        box-shadow: 1px 4px 15px 1px rgba(0, 0, 0, 0.11);

        > td {
          background: white !important;
          height: max-content !important;
        }
      }

      &:before {
        content: "@";
        display: block;
        line-height: 10px;
        text-indent: -99999px;
      }

      .ant-pagination {
        padding: 16px !important;
        text-align: right;
      }
    }

  }
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
  visibility: hidden;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.ant-select-selection--single {
  padding: 10px 16px;
}


.ant-table-placeholder {
  border-top: none;
}

.empty-grades-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  &__button {
    width: 240px;
    height: 56px;
    border-radius: 8px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.11);
    background-color: #9134d8;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Rubik';
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    color: #ffffff;
    cursor: pointer;
    border: none;

    &:hover,
    &:focus,
    &:visited {
      background: $honey-flower;
      color: $white-color;
    }
  }

  &__thumbnail {
    width: 318px;
    height: 318px;
    object-fit: contain;
    margin-bottom: 47px;
  }

  &__text {
    font-family: 'Rubik';
    font-size: 30px;
    font-weight: 500;
    line-height: 1;
    color: #524e59;
    margin-bottom: 10px;
  }

  &__sub-text {
    font-family: 'Rubik';
    font-size: 18px;
    font-weight: normal;
    line-height: 0.56;
    color: #524e59;
    margin-bottom: 60px;
  }
}
