.datepicker .ant-input,
.ant-input:focus,
.ant-input:hover,
.ant-input:active,
.ant-select-selection,
.ant-input-number {
  height: 47px;
  width: 290px;
}
//FLV-156
.Container-Pref-filter {
  position: relative;
  .autoContainers-Pref-filter {
    position: absolute;
    left: -290px;
    bottom: 0px;
    background: white;
    z-index: 1;
    width: 290px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    .options {
      //padding: 0.5em 1em;
      //text-align: right;
      cursor: pointer;
      margin-top: 4px;
      margin-bottom: 4px;
      padding: 0 16px;
      overflow: hidden;
      font-size: 14px;
      line-height: 40px;
      height: 40px;
      text-overflow: ellipsis;
    }
    .options:hover {
      background-color: #f1f1f1;
    }
  }
}
//FLV-156
