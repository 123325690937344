@import 'assets/stylesheet/variables.scss';

.add-student-modal {
  p {
    margin-top: 20px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .ant-input {
    box-shadow: 0 4px 34px 0 rgba(0, 0, 0, 0.06);
    background-color: white !important;
    padding: 24px 16px;
    font-size: 20px;
    font-weight: 500;
    color: #524e59;
  }

  .ant-select {
    width: 100%;
  }

  .ant-modal-header {
    display: block;
    background-color: white;
    padding: 40px 40px;
    padding-bottom: 20px;
    border: none;

    .ant-modal-title {
      color: #524E59;
      font-size: 24px;
    }
  }

  .ant-modal-content {
    background-color: white;
    color: #7D7A83;

    tbody {
      td {
        &:nth-child(2) {
          font-weight: 500;
        }
      }
    }

    .ant-modal-body {
      padding: 0px 35px 18px 35px !important;

      span {
        &.ant-radio {
          display: none;
        }
      }

      .ant-input {
        border-radius: 5px;
        background-color: #F7F7F8;
        border: none;
      }

      .ant-radio-group {
        width: 100%;

        .ant-radio-wrapper {
          font-weight: 500;
          border: 2px solid #7D7A83;
          border-radius: 5px;
          width: 29%;
          text-align: center;

          &:nth-child(1) {
            &.ant-radio-wrapper-checked {
              background-color: #27AE60;
              border-color: #27AE60;
              color: white;
            }
          }

          &:nth-child(2) {
            &.ant-radio-wrapper-checked {
              background-color: #EB5757;
              border-color: #EB5757;
              color: white;
            }
          }

          &:nth-child(3) {
            &.ant-radio-wrapper-checked {
              background-color: #F2BD1D;
              border-color: #F2BD1D;
              color: white;
            }
          }
        }
      }
    }
  }

  .ant-modal-footer {
    background-color: white;
    border: none;
    text-align: left;
    padding: 40px;
    //padding-bottom: 70px;

    div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .ant-btn {
      background-color: $magenta-color;
      font-weight: 500;
      font-size: 12px;
      border: 0px;
      color: #524E59;
      height: 50px;
      width: 48%;
      background: white;
      border: 1px solid #524E59;
      font-weight: bold;
      border-radius: 10px;
      order: 2;
      margin-right: 10px;
    }

    .ant-btn-primary {
      background-color: $magenta-color;
      font-weight: 500;
      font-size: 12px;
      border: 0px;
      color: white;
      order: 1;
    }

    .ant-btn-primary:hover,
    .ant-btn-primary:focus,
    .ant-btn-primary:visited,
    .ant-btn-primary:active {
      background: $honey-flower;
      border-radius: 8px;
      color: $white-color;
      border: none;
      outline: none;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }

  .ant-modal-close {
    display: inline;
    top:20px;
    .anticon {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ECECEC;
      border-radius: 100px;
    }
  }
}
