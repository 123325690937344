.next-class-card {
  width: 355px;
  height: 355px;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;

    &.shadow-sm {
      box-shadow: 1px 1px 1px 0 rgba(44, 11, 74, 0.1) !important;
    }
  
    &.gradient-color {
      position: relative;
      background: linear-gradient(
        148deg,
        rgba(176, 45, 243, 1) 0%,
        rgba(176, 45, 243, 1) 15%,
        rgba(255, 137, 66, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b02df3', endColorstr='#ff8942', GradientType=0);
  
      &.mobile-view {
        @media screen and (max-width: 767px) {
          //border-radius: 0 !important;
          //position: absolute;
          left: 0;
          top: 0;
          right: 0;
          z-index: 3;
          margin: 0 0 1rem 0 !important;
          border-radius: unset;
        }
      }
  
      .join-btn-hero {
        background-color: #fff;
        border-radius: 4px;
        height: 28px;
        width: 90px;
        border: none;
        text-transform: uppercase;
        text-align: center;
        outline: none;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.66px;
        color: #9351cd;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:disabled {
          background-color: #d7d7d7;
          cursor: no-drop;
        }
        @media screen and (max-width: 767px) {
          display: block;
          margin-bottom: -3px;
        }
      }
  
      .circle-1 {
        background-color: rgba(255, 255, 255, 0.1);
        position: absolute;
        top: -6px;
        right: -9px;
        height: 72px;
        width: 72px;
        border-radius: 100px;
        z-index: 4;
      }
  
      .circle-2 {
        background-color: rgba(255, 255, 255, 0.1);
        position: absolute;
        top: -12px;
        right: -12px;
        height: 92px;
        width: 90px;
        border-radius: 100px;
        z-index: 3;
      }
  
      .circle-3 {
        background-color: rgba(255, 255, 255, 0.1);
        position: absolute;
        top: -17px;
        right: -15px;
        height: 112px;
        width: 110px;
        border-radius: 100px;
        z-index: 2;
      }
  
      .circle-4 {
        background-color: rgba(255, 255, 255, 0.1);
        position: absolute;
        top: -22px;
        right: -22px;
        height: 132px;
        width: 130px;
        border-radius: 100px;
        z-index: 1;
      }
  
      .libraryIcon {
        position: absolute;
        top: 16px;
        right: 17px;
        height: 22px;
        width: 22px;
        z-index: 5;
      }
  
      .cardTitle-row {
        display: block;
        padding-bottom: 17px;
  
        &.flex-mobile-view {
          @media screen and (max-width: 767px) {
            display: flex;
            align-items: flex-end;
            padding-bottom: 8px;
          }
        }
  
        &.next-text-row {
          @media screen and (max-width: 767px) {
            padding-bottom: 8px;
          }
        }
  
        .cardTitle {
          font-weight: 500;
          font-size: 16px;
          text-align: left;
          color: #000;
          text-transform: uppercase;
  
          &.date-month {
            @media screen and (max-width: 767px) {
              font-size: 24px;
              text-transform: capitalize;
            }
          }
        }
      }
  
      .next-class {
        color: #fff;
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-weight: 500;
        text-align: left;
      }
  
      .time-slot {
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        color: #fff;
        @media screen and (max-width: 767px) {
          border-radius: 0 !important;
          padding-left: 10px;
          padding-bottom: 2px;
        }
      }
    }
  }
  