@import 'assets/stylesheet/variables.scss';

.class-list {
  padding: 25px 20px;
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: 15px;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.07);
  height: 73vh;
  overflow-y: auto;
  position: relative;
  @media(max-width: $max-resp-width) {
    padding: 25px 25px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: none;
    margin-right: 5px;
    margin-left: 5px;
    &.no-overflow {
      overflow: unset;
      height: auto;
      min-height: 50vh;
    }
  }

  .load-more {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: min-content;
    align-items: center;
    justify-content: center;
    background: #FFF;
    opacity: 0.9;

    .ant-spin {
      padding: 0 15px;
      display: flex;
    }

    @media(max-width: $max-resp-width) {
      display: none;
    }
  }

  .see-all-container {
    position: absolute;
    visibility: hidden;
    bottom: -16px;
    left: 0px;
    width: 100%;
    height: 110px;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0) 100%);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0px 20px;
    z-index: 1;

    @media(max-width: $max-resp-width) {
      visibility: visible;
    }

    .see-all-btn {
      width: 100%;
      background-color: #E6E8EB;
      border: none;
      text-transform: capitalize;
      height: auto;
      padding: 10px 0px !important;
      margin-bottom: 20px;
      @media(max-width: $max-resp-width) {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .ant-spin {
        @media(max-width: $max-resp-width) {
          padding: 0 10px;
          transform: translateY(2px);
        }
      }
    }
  }

  .batch-details .container .main .card-container > .ant-tabs-card .ant-tabs-tab-active, .batch-details .container .main [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
    box-shadow: 0px 15px rgba(0, 0, 0, 0.07);
    color: black;
    font-weight: 500;
    font-size: 18px;
  }

  .list-item {
    padding: 15px 10px;
    display: flex;
    border-radius: 5px;
    position: relative;

    @media(max-width: $max-resp-width) {
      &::before {
        content: "3";
        text-indent: -999px;
        width: 20px;
        height: 20px;
        background-color: #cfeef5;
        position: absolute;
        left: -21px;
        top: 50%;
        margin-top: -10px;
        z-index: 1;
        border-radius: 100%;
        border: 5px solid #fff;
      }
      .blue-circle {
        content: "3";
        text-indent: -999px;
        padding: 3px;
        background-color: white;
        position: absolute;
        left: -22px;
        top: 50%;
        margin-top: -10px;
        z-index: 1;
        border-radius: 100%;
        border: 2px solid #0097bb;
        z-index: 3;

        .inner {
          background-color: #00beff;
          width: 11px;
          height: 11px;
          border-radius: 100%;
        }
      }
    }

    &:hover {
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
    }

    /*@media screen and (max-width: 1050px) {
      flex-direction: column !important;
    }*/

    &.upcoming {
      .item-container {
        flex-wrap: wrap !important;

        .pointer {
          cursor: pointer;
        }

        .ant-btn {
          background-color: $magenta-color;
          width: auto;
          height: auto;
          padding: 5px 2px;
          box-sizing: border-box;

          span {
            color: white;
            font-weight: 500;
            font-size: 12px;
          }
        }

        &:nth-child(1) {
          border-radius: 1px solid black;
          flex-basis: 26%;
          border-right: 2px solid #EBEBEB;
          justify-content: flex-start;


        }

        &:nth-child(2) {
          flex-basis: 60%;
          padding-left: 20px;

          p {
            font-weight: 500;
            line-height: 20px;
          }
        }

        &:nth-child(3) {

          flex-basis: 25%;

        }

      }
    }

    &.previous {
      .item-container {
        flex-wrap: wrap !important;

        .ant-btn {
          border: 2px solid $magenta-color;
          width: auto;
          height: auto;
          padding: 5px 2px;
          box-sizing: border-box;

          &:hover {
            box-shadow: 0px 0px 10px rgba(145, 52, 216, 0.5)
          }

          span {
            color: $magenta-color;
            font-weight: 500;
            font-size: 12px;
          }
        }

        &.completed {
          .ant-btn {
            width: auto;
            height: auto;
            padding: 5px 2px;
            box-sizing: border-box;
            font-weight: 500;
            border: 2px solid #219653;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
              box-shadow: 0px 0px 10px rgba(33, 150, 83, 0.5)
            }

            span {
              color: #219653;
              font-weight: 500;
              font-size: 12px;
            }
          }
        }

        &:nth-child(1) {
          border-radius: 1px solid black;
          flex-basis: 26%;
          border-right: 2px solid #EBEBEB;
          justify-content: flex-start;

        }

        &:nth-child(2) {
          flex-basis: 60%;
          @media(max-width: $max-resp-width) {
            flex-basis: 72%;
          }
          padding-left: 20px;

          p {
            font-weight: 500;
            line-height: 20px;
          }
        }

        &:nth-child(3) {

          flex-basis: 21%;
        }

        &:nth-child(4) {
          flex-basis: 21%;
        }
      }
    }

    .item-container {
      padding: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      position: relative;

      @media(max-width: $max-resp-width) {
        &:nth-child(1) {
          flex-basis: 49% !important;
          padding-right: 15px;
          border-right: 0px !important;
          display: flex;
          justify-content: center !important;
          align-items: center;

          &::after {
            width: 2px;
            height: 50%;
            background-color: #D8D8D8;
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
            content: 's';
            text-indent: -999px;
          }
        }

      }

      .date {
        font-weight: 500;
        color: black;
      }

      .time {
        font-size: 12px;
        margin-top: 5px;
      }

      @media(max-width: $max-resp-width) {
        h2 {
          font-size: 14px;
        }
        p {
          font-style: normal !important;
          font-weight: normal !important;
          font-size: 12px !important;
          line-height: 16px;
          color: #524E59;
        }
        .date {
          font-size: 20px;
        }
        .time {
          font-size: 12px;
        }
      }
    }

  }

  .class-list-blue-bar {
    width: 3px;
    height: 100%;
    background: linear-gradient(0deg, rgba(207, 238, 245, 1) 97%, rgba(255, 255, 255, 0) 100%);
    position: absolute;
    left: 12px;
    top: 15px;

  }

  .join-btn-mobile {
    background-color: #a800ce;
    border-color: #a800ce !important;
    height: auto;
    padding: 3px 30px !important;
    margin-top: 13px;

    span {
      font-weight: bold !important;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 0.661157px;
      text-transform: uppercase;

      color: #FFFFFF !important;

    }
  }

  .view-details-mobile {
    border-radius: 4px;
    height: auto;
    padding: 4px 20px !important;
    margin-top: 13px;
    border-width: 1px !important;

    span {
      font-size: 12px !important;
      font-weight: 700 !important;
    }
  }
}
