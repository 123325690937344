@import 'assets/stylesheet/variables.scss';

.homework-content {
  color: #7D7A83;
  font-size: 15px;

  .content-section {
    .title {
      color: $magenta-color;
      font-weight: 500;
    }

    a, p {
      margin-top: 8px;

      &.content {
        color: #524e59;
      }
    }

    ul {
      padding-left: 0;
      list-style: none;
    }
  }

  .pills {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;

    .pill {
      margin-bottom: 2px;
      display: inline-block;
      margin-right: 10px;
    }
  }

  .link-cards {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;


    .link-card {
      width: 240px;
      min-height: 240px;
      border-radius: 15px;
      overflow: hidden;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.11);
      margin-right: 20px;
      margin-top: 20px;
      cursor: pointer;

      .card-image {
        width: 100%;
        height: 140px;
        object-fit: cover;
      }

      .card-footer {
        padding: 10px 15px;

        .author {
          font-weight: normal;
          color: #524E59;
          font-size: 12px;
          margin: 0px;

          .name {
            color: $magenta-color;
          }
        }
      }
    }
  }

  .seperator-20 {
    width: 100%;
    height: 20px;
  }
}

.homework-modal {
  .ant-modal-header {
    display: block;
    background-color: white;
    padding: 40px 35px 30px 35px !important;
    border: none;

    .ant-modal-title {
      color: #524E59;
      font-size: 24px;
    }
  }

  .ant-modal-content {
    background-color: white;

    .ant-modal-body {
      padding: 0px 35px 18px 35px !important;
      @media(max-width: $max-resp-width) {
        padding: 30px 15px !important;
        margin-top: 15px;
        box-sizing: border-box;
      }
    }
  }

  .ant-modal-footer {
    background-color: white;
    border: none;
    text-align: left;
    padding: 40px 27px !important;

    .ant-btn {
      &:nth-child(1) {
        display: none;
      }
    }

    .ant-btn-primary {
      background-color: $magenta-color;
      font-weight: 500;
      font-size: 12px;
      padding: 10px 33px;
      border-radius: 8px;

      &:hover,
      &:focus,
      &:visited {
        background: $honey-flower;
        color: $white-color;
      }
    }
  }

  .ant-modal-close {
    display: inline;
    top: 20px;

    .anticon {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ECECEC;
      border-radius: 100px;
    }
  }
}
