@import 'assets/stylesheet/variables.scss';

.lesson-plan {
  font-size: 15px;

  .attachments {
    display: flex;
    margin-top: 20px;

    img {
      width: 250px;
      height: 200px;
      object-fit: cover;
      border-radius: 15px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.11);
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }

  .content-section {
    .title {
      color: $magenta-color;
      font-weight: 500;
    }

    a, p {
      margin-top: 8px;

      &.content {
        color: #524e59;
      }
    }

    .link-cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
    }
  }
}
