.pill {
    border-radius: 5px;
    padding: 2px 10px;
    font-weight: 500;
    font-size: 10px;
    margin-bottom: 2px;
    display: inline-block;

    &.type-1 {
        background-color: #FEEBD6;
        color: rgba(244, 113, 43, 1);
    }
    &.type-2 {
        background-color: rgba(31, 185, 219, 0.16);
        color: #1fb9db;
    }
    &.type-3 {
        background-color: #DEF2E6;
        color: #27AE60
    }
    &.type-4 {
        background-color: #FCE4E4;
        color: #EB5757;
    }
    &.type-5 {
        background-color: #E9D6F6;
        color: #9351CA;
    }
    &.type-6 {
        background-color: #FCCAC0;
        color: #EE2A1A;
    }
}
