@import "assets/stylesheet/variables.scss";

$shadowType1: 0px 0px 14px rgba(0, 0, 0, 0.10);

.overlay-filter {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(246, 246, 246, 0.5);
  top: 0px;
  left: 0px;
  z-index: 1;
}

.teacher-filter-list {
  width: 320px;
  background: $white-color;
  padding: 10px 16px;
  border: solid 1px $iron-color;
  border-radius: 8px;
  position: absolute;
  right: 0;
  top: 53px;
  z-index: 2;
  box-shadow: $shadowType1;

  @media(max-width: $max-resp-width) {
    padding-top: 28px;
    width: 320px;
    background: #ffffff;
    padding: 0 16px 10px 16px;
    border: none;
    border-radius: 8px;
    z-index: 2;
    position: fixed !important;
    left: 0px;
    bottom: 2px;
    height: 400px;
    width: 100vw;
    border-radius: 15px;
    padding: 20px;
    overflow: hidden;
    top: unset !important;
    z-index: 2 !important;

    /*&::after {
      content: " ";
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.4);
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: -1 !important;
    }*/

  }

  .ant-btn {
    margin: 20px 6px;

  }

  .ant-collapse-arrow {
    right: 0 !important;
  }

  .student-listing-filter {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border: 0px !important;

    .ant-collapse-header {
      border-bottom: 1px solid #EEEEEF !important;
    }

    .teacher-listing-header .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      border: none;
    }

    .ant-btn {
      border-radius: 10px;
    }

    .ant-collapse {
      background-color: transparent;
      border: none;

      &-content {
        border: none;
        max-height: 40vh;
        overflow-y: auto;
      }

      &-content-box {
        padding: 10px 0 0 0;
      }

      > .ant-collapse-item {
        border: none;

        > .ant-collapse-header {
          padding: 12px 0;
          font-size: 20px;
          color: $default-text-color;
          font-weight: 500;
          border-bottom: solid 1px $iron-color;
          border-radius: 0;

        }
      }

      > .ant-collapse-item:last-child {
        > .ant-collapse-header {
          border-bottom: none;
        }
      }
    }

    .ant-btn-primary, .ant-btn-primary:hover, .ant-btn-primary:focus, .ant-btn-primary:active, .ant-btn-primary:visited {
      background-color: $magenta-color;
    }

    .filter-count {
      display: flex;
      align-items: center;

      span {
        width: 22px;
        height: 22px;
        font-size: 12px;
        background-color: #9134D8;
        border-radius: 100px;
        margin-left: 20px;
        color: white;
        display: inline-block;
        text-align: center;
      }
    }
  }

}

.ant-dropdown-trigger {
  width: 180px;
  height: 48px;
  font-size: 16px;
  line-height: 48px;
  color: $default-text-color;
  border: solid 1px $iron-color;
  border-radius: 8px;
  background: $white-color;
  padding: 0 16px;
  @media(max-width: $max-resp-width) {
    width: 52px !important;
    height: 48px;
    font-size: 16px;
    line-height: 48px;
    color: #524e59;
    border: solid 1px #DCDCDE;
    border-radius: 8px;
    background: #ffffff;
    padding: 0 16px;
    span {
      display: none;
    }
    margin-right: -12px !important;
  }

  .filter-count {
    span {
      width: 11px;
      margin: 0 0 8px 5px;
      height: 11px;
    }
  }
}
