.close-icon {
  font-family: 'Rubik';
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  color: #ffffff;
  border-radius: 50%;
  background-color: #dcdcdc;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
}

.component-multi-value-input {
  &__top-container {
    display: flex;
    align-items: center;
    width: 95%;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__add-icon {
    margin-left: 16px;
    width: 56px;
    height: 55px;
    border-radius: 8px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.11);
    border: solid 0 #979797;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #514e59;
    font-size: 30px;
    cursor: pointer;
  }

  &__input {
    border: none;
    outline: none;
    width: -webkit-fill-available;
    padding: 0;
    line-height: 1;

    &::placeholder {
      font-family: 'Rubik';
      font-size: 20px;
      font-weight: normal;
      line-height: normal;
      color: rgba(41, 44, 46, 0.3);
    }
  }

  &__input-container {
    display: flex;
    align-items: center;
    height: 56px;
    padding: 16px 16px;
    border-radius: 8px;
    box-shadow: 0 4px 34px 0 rgba(0, 0, 0, 0.06);
    border: solid 0 #979797;
    background-color: #ffffff;
    width: 93%;
  }

  &__list-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-right: 15px;
    margin-left: -10px;
    margin-top: -10px;

    &--list-item {
      display: flex;
      align-items: center;
      // width: 150px;
      justify-content: space-between;
      box-shadow: 1px 2px 14px 0px rgba(0, 0, 0, 0.08);
      padding: 9px 7px 9px 15px;
      border-radius: 25px;
      margin-left: 10px;
      margin-top: 10px;
    }
  }
}

.link-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Rubik';
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  color: rgba(41, 44, 46, 0.8);
}