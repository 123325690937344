@import "assets/stylesheet/variables.scss";

.view-add-session-form {
  &__column {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__control {
    display: flex;
    flex-direction: column;

    &--input {
      width: 520px;
      height: 56px;
      border-radius: 8px;
      box-shadow: 0 4px 34px 0 rgba(0, 0, 0, 0.06);
      border: solid 0 #979797;
      background-color: #ffffff;
      outline: none;
      padding: 16px 24px;

      &::placeholder {
        font-family: "Rubik";
        font-size: 20px;
        letter-spacing: -0.15px;
        text-align: left;
        color: rgba(41, 44, 46, 0.3);
      }
    }

    &--input-xl {
      width: 987px;
    }

    &--error {
      font-family: "Rubik";
      font-size: 12px;
      line-height: 0.71;
      color: red;
      margin: 10px 0;
    }

    &--textarea {
      width: 520px;
      height: 116px;
      border-radius: 8px;
      box-shadow: 0 4px 34px 0 rgba(0, 0, 0, 0.06);
      border: solid 0 #979797;
      background-color: #ffffff;
      padding: 24px;

      &::placeholder {
        font-family: "Rubik";
        font-size: 20px;
        letter-spacing: -0.15px;
        text-align: left;
        color: rgba(41, 44, 46, 0.3);
      }
    }
  }

  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }
}

.section-title {
  font-family: "Rubik";
  font-size: 14px;
  font-weight: 500;
  line-height: 0.71;
  color: #9134d8;
  margin-bottom: 32px;
}

.view-add-session {
  margin-top: 40px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;

    &--cancel-button {
      border-radius: 8px;
      border: solid 1px #000000;
      background-color: transparent;
      width: 240px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Rubik";
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.89px;
      text-align: center;
      color: #524e59;
      margin-right: 16px;
      cursor: pointer;
    }

    &--submit-button {
      width: 240px;
      height: 56px;
      border-radius: 8px;
      border: none;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.11);
      background-color: #9134d8;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Rubik";
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.89px;
      text-align: center;
      color: #ffffff;
      cursor: pointer;

      &:hover,
      &:focus,
      &:visited {
        background: $honey-flower;
        border-radius: 8px;
        color: $white-color;
        border: none;
        outline: none;
      }
    }
  }

  &__main-container {
    &--bottom-section {
    }

    &--top-section {
      margin-bottom: 12px;
    }
  }

  &__tabs-details {
    margin-top: 32px;

    &--header-text {
      font-family: "Rubik";
      font-size: 14px;
      font-weight: 500;
      line-height: 0.71;
      color: #979797;
      margin-bottom: 8px;
    }

    &--text-editor-container {
      width: 1059px;
      height: auto;
      border-radius: 8px;
      box-shadow: 0 4px 34px 0 rgba(0, 0, 0, 0.06);
      border: solid 0 #979797;
      background-color: #ffffff;
    }
  }

  &__tabs-switcher {
    display: flex;
    align-items: center;

    &--tab {
      margin-right: 60px;
      font-family: "Rubik";
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.63;
      letter-spacing: normal;
      text-align: left;
      color: rgba(145, 52, 216, 0.6);
      padding: 19px 0 22px;
      cursor: pointer;

      &:last-child {
        margin-right: unset;
      }

      &.active {
        color: rgb(145, 52, 216);
        border-bottom: 3px solid #f2bd1d;
      }
    }
  }
}

.quill {
  height: 280px;
}

.ql-container.ql-snow {
  border: none;
}

.ql-toolbar.ql-snow {
  border: none;
}
.tox-tinymce {
  border: 0px !important;
  * {
    border-color: #fff !important;
  }
  .tox-toolbar__primary {
    background: #fff !important;
  }
}
.tox-notification--warning {
  visibility: hidden !important;
  display: none !important;
}
