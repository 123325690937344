@import 'assets/stylesheet/variables.scss';

.announcement-card {
    display: flex;
    flex-direction: column;
    width: 90%;
    transition: box-shadow 300ms ease-in-out;
    box-sizing: border-box;
    border-radius: 5px;
    overflow: hidden;
    background-color: white;
    box-shadow: 0px 0px 25px rgba(0,0,0,0.1);
    margin: auto;
    padding: 20px;

    .close-btn {
        font-weight: bold;
        color: black;
        background-color: rgba(0,0,0,0.1);
        border-radius: 100px;
        width: 25px;
        height: 25px;
        text-align: center;
        cursor: pointer;
    }

    .spacer {
        width: 100%;
        height: 15px;
    }

    .card-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .link {
            font-size: 12px;
            color: $magenta-color;
            font-weight: 500;
            cursor: pointer;
        }
    }

    .card-content {
        .announcement {
            font-size: 14px;
            margin-top: 20px;
            border-bottom: 1px solid #D8D8D8;
            padding-bottom: 15px;

            &:first-of-type {
                margin-top: 5px;
            }

            &:last-of-type {
                border-bottom: none;
            }

            .type-date-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .pill {
                    display: inline-block;
                }
                .date {
                    font-size: 10px;
                    color: #86838B;
                }
            }

            .title {
                font-weight: 500;
                @media(max-width: $max-resp-width) {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    color: #000000;
                }
            }
            .desc {
                color: #86838B;
                font-size: 11px;
                @media(max-width: $max-resp-width) {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 16px;
                    color: #524E59;
                    opacity: 0.7;
                }
            }
        }
    }


    .card-footer {
        .add-btn {
            background-color: $magenta-color;
            font-weight: bold;
            width: 100px;
            color: white;
            &:hover,
            &:focus,
            &:visited {
                background: $honey-flower;
                color: $white-color;
            }
        }
    }


}

.ant-modal-mob {
    @media(max-width: $max-resp-width) {
        * {
            transition: none !important;
            animation: none !important;
        }
        .ant-modal-close-x {
            visibility: hidden;
            position: fixed;
            left: -15px;
            top: 10px;
            &::after {
              content: "<";
              color: black;
              z-index: 999;
              width: 10px;
              height: 10px;
              visibility: visible;
              font-size: 25px;
              font-weight: 300;
              position: absolute;
              top: -5px;
              left: 31px;
            }
        }
    }
    .announcement-card {
        @media(max-width: $max-resp-width)  {
            background-color: #E5E5E5;
            height: 100%;
            margin-top: 0px !important;
            padding-top: 55px;
            padding: 0px;

            .card-header {
                background-color: #fff;
                padding: 20px 18px;
                padding-top: 57px;
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
                h4 {
                    text-transform: capitalize;
                }
            }
            .card-content {
                background-color: #fff;
                padding: 20px;
                @media(max-width: $max-resp-width) {
                    height: 80% !important;
                }
            }
            .card-footer {
                height: 18% !important;
                background: #fff;
                padding: 20px;
            }

            .container{
                padding: 20px;
            }

            .icon-text {
                font-style: normal;
                font-weight: 500;
                font-size: 32px;
                line-height: 26px;
                color: #000000;
                svg {
                    display: none;
                }
            }
            .close-btn {
                @media(max-width: $max-resp-width) {
                    visibility: hidden;
                    &::after {
                        content: "<";
                        visibility: initial;
                        font-size: 30px;
                        font-weight: 100;
                        position: fixed;
                        top:10px;
                        left: 20px;
                    }
                }
            }
            .add-btn {
                @media(max-width: $max-resp-width) {
                    width: 100%;
                    height: 52px;
                    border-radius: 8px;
                }
            }
        }
    }
}
