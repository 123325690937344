@import "assets/stylesheet/variables.scss";


.ant-calendar-picker {
  width: 100%;

  svg {
    fill: $default-text-color;
    width: 18px;
    height: 18px;
    transform: translateX(-8px);
  }

  input {
    font-size: 16px !important;
    font-weight: 500 !important;
    color: $default-text-color !important;
  }
}

.ant-calendar-picker-container {

  .ant-calendar-selected-day {
    .ant-calendar-date {
      background: $magenta-color;
      color: $white-color;
      border: none;
    }
  }

  .ant-calendar-today {
    .ant-calendar-date {
      border-color: $magenta-color;
      color: $magenta-color;
    }
  }

  .ant-calendar-today {
    &.ant-calendar-selected-day {
      .ant-calendar-date {
        border-color: $magenta-color;
        color: $white-color;
      }
    }
  }

  .ant-calendar-cell {
    :hover {
      background: $magenta-color;
      color: $white-color;
    }
  }
}

