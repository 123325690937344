.euro-login-container {
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(359deg, #552d83, #432870 64%, #361e5f);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  .helper-text {
    margin-top: 24px;
    font-size: 36px;
    font-weight: 500;
  }
  .sub-helper-text {
    font-weight: 100;
  }
}