.schedule-modal {
    .ant-modal-close {
        display: inline;
        top:20px;
    }
    .ant-modal-body {
        display: block;
        margin-top: 14px;
        padding: 34px 18px !important;
        .schedules {
            h2 {
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 30px;
                /* or 125% */

                display: flex;
                align-items: center;

                color: #000000;
                margin-bottom: 16px;
            }
            h4 {
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                line-height: 30px;
                /* or 125% */


                /* Text Grey */

                color: #524E59;
                opacity: 0.8;
                margin-bottom: 40px;
            }
        }
    }
    .ant-modal-header {
        display: block;
    }
}