@import "assets/stylesheet/variables.scss";

$gutter-spacing: 48px;

.admin {
  &-sidenav {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 270px;
    top: 0;
    left: 0;
    position: fixed;
    padding-top: $gutter-spacing;
    background: $white-color;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.11);
    @media(max-width: $max-resp-width) {
      margin-left: -270px;
      padding-top: 50px;
      z-index: 1;
      &.nav-visible {
        margin-left: 0px;
      }
      .admin-logo {
        visibility: hidden;
      }
    }

    nav {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding-bottom: $gutter-spacing;
      justify-content: space-between;
      overflow: auto;
    }
  }

  &-logo {
    margin: 0 0 $gutter-spacing $gutter-spacing;
    display: block;
  }

  &-top-nav {
    padding: 0;
    margin: 0;
    list-style-type: none;
    align-items: center;
    display: flex;
    flex-direction: column;

    li {
      padding-left: 30px;
      font-size: 20px;
      color: $default-text-color;
      font-weight: 500;
      display: flex;
      flex-direction: row;
      height: 72px;
      width: 90%;
      align-items: center;
      position: relative;
      cursor: pointer;
      border-radius: 8px;

      &:hover {
        background: $light-magenta;
      }

      svg {
        margin-right: 15px;
      }

      &.active {
        margin: 5px 0;
        color: $white-color;
        background: $magenta-color;

      }

    }
  }
}
