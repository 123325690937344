.component-collections {
  overflow: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }
  .collections-list-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 26px;
    margin-left: 30px;
  }
  .collection-selector {
    margin-top: 64px;
    padding: 30px;
    position: relative;
    z-index: 99;
    background: #fff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
    img {
      cursor: pointer;
    }
    .collection-type {
      width: 196px;
      height: 65px;
      opacity: 0.8;
      border-radius: 6px;
      object-fit: cover;
      margin-right: 16px;
      display: inline-block;
      opacity: 0.8;
      transition: all 0.2s;
      cursor: pointer;
      filter: grayscale(100%);
      &.active-collection {
        filter: grayscale(0%);
        width: 260px;
        height: 82px;
        opacity: 1.0;
      }
    }
    .collection-img-container {
      width: 100%;
      overflow: auto;
      display: flex;
      align-items: center;
      min-height: 92px;
      scroll-behavior: smooth;
    }
    .helper-text {
      font-size: 30px;
      font-weight: bold;
      margin-top: 16px;
      margin-bottom: 24px;
    }
  }
  .secondary-helper-text {
    font-size: 20px;
    font-weight: bold;
    margin-left: 30px;
    margin-top: 22px;
    text-transform: uppercase;
  }
  @media screen and (max-width: 1024px) {
    .collection-selector {
      padding: 30px 16px;
    }
    .collections-list-container {
      justify-content: center;
    }
  }
}