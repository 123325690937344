@import "assets/stylesheet/variables.scss";

$grey-color: #524e59;

.admin {
  &-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100%;
    //overflow: hidden;
    padding-left: 270px;

    .mobile-nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      display: none;
      margin-bottom: 20px;

      .ant-btn {
        border: none;
        padding-left: 0px;
      }

      .on-top {
        z-index: 2;
      }
    }

    @media(max-width: $max-resp-width) {
      padding-left: 0px;
      .mobile-nav {
        display: flex;
      }
    }
    // background: $light-gray-color;
    &.no-nav-header {
      .mobile-nav {
        display: none;
      }

      .admin-right-content {
        background-color: #E8E8E8;
        padding: 0px;
        position: relative;
      }

      .back-btn {
        position: absolute;
        top: 10px;
        left: -40px;
        visibility: hidden;

        &::after {
          content: '<';
          visibility: visible;
          font-size: 25px;
          font-weight: 300;
        }
      }

      .header {
        .back-btn {
          left: -22px;
        }
      }

    }
  }

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // border-bottom: solid 1px $light-gray-color2;
    margin-bottom: 15px;
    align-items: center;
    @media(max-width: $max-resp-width) {
      margin-bottom: 25px;
    }

    h1 {
      line-height: 36px;
      font-size: 24px;
      margin: 0;
      //padding: 20px 0 7px 0;
      color: #000;
      font-weight: 500;
    }
  }

  &-right-content {
    padding: 28px;
    flex: 1;
    overflow-y: auto;

    .back-btn {
      color: $grey-color;
      border: none;
      margin: none;
      margin-left: -12px;
      font-size: 16px;
      width: auto;
      height: auto;
      padding: 0 !important;
      margin-left: 3px;
      text-transform: uppercase;
    }

    .batches-listing-header {
      .search-box {
        display: none;
      }
    }

    // background-color: #ffffff;
  }
}
