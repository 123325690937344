.view-all-announcement-modal {
  .ant-modal-footer {
    display: none !important;
  }

  .announcement-card {
    width: 100%;
    margin: none;

    .card-content {
      height: 500px;
      overflow-y: scroll;

    }
  }

  &.no-scroll {
    .card-content {
      overflow-y: hidden;
      height: 550px;
    }
  }

  .ant-modal-close {
    display: inline;
    top: 15px;

    .anticon {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ECECEC;
      border-radius: 100px;
    }
  }
}
