@import "assets/stylesheet/variables.scss";

$grey-color: #524E59;

.add-new-student {
  .admin-right-content {
    padding: 0px;
    overflow: hidden;
  }

  .p-28 {
    padding: 28px;
    padding-bottom: 0px;
  }

  .admin-right-content {
    padding-bottom: 0px;
  }

  h1, h2, h3, h4, h5 {
    color: $grey-color;
  }

  .seperator {
    margin-top: 40px
  }

  .results {
    p {
      padding: 20px 0;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.222222px;
      color: #524E59;

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.222222px;
        color: #524E59;
        opacity: 0.5;
      }
    }
  }

  .student-list {
    display: flex;
    flex-wrap: wrap;
    max-height: 60vh;
    overflow-y: auto;
    padding-bottom: 6.6%;
    padding-left: 28px;
  }

  .loader {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }

  .search-student {
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 10px;
      text-transform: uppercase;
      color: #4C1A73;
      margin-bottom: 15px;
    }

    .ant-input {
      background: #FFFFFF;
      box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.06);
      border-radius: 8px;
      border: 0px;
      padding: 25px;
    }

  }

  .seperator-15 {
    width: 100%;
    height: 15px;
  }

  .back-btn {
    color: $grey-color;
    font-weight: 500;
    border: none;
    margin: none;
    margin-left: -12px;
    font-size: 16px;
    width: auto;
    height: auto;
    padding: 0 !important;
    margin-left: 3px;
  }

  .add-student-cards {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    height: 55vh;
    overflow-y: scroll;
    overflow-x: auto;
    padding-left: 28px;
    padding-top: 28px;
    padding-bottom: 20px;
  }

  .load-more {
    display: flex;
    flex-direction: row;
    width: calc(100% - 247px);
    height: min-content;
    align-items: center;
    justify-content: center;
    background: #FFF;
    opacity: 0.7;

    .ant-spin {
      padding: 0 15px;
      display: flex;
    }
  }
}
