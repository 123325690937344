@import 'assets/stylesheet/variables.scss';

$shadowType1: 0px 0px 14px rgba(0, 0, 0, 0.10);

.admin-teacher-list {
  height: 80vh;
  box-shadow: none;

  .ant-pagination {
    padding: 10px 16px 16px 16px !important;
    text-align: right;
  }

  table {
    border-collapse: separate;
  }

  .ant-table-selection-col, .ant-table-selection-column {
    display: none;
  }

  .ant-table-tbody {
    .ant-radio-inner {
      border-color: $magenta-color;

      &:after {
        background-color: $magenta-color;
        display: inline;
      }
    }

    td {
      border-top: 3px solid white;
      border-bottom: 3px solid white;

      &:nth-of-type(1) {
        border-left: 3px solid white;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      &:nth-of-type(7) {
        border-right: 3px solid white;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      &:nth-of-type(6) {
        p {
          text-align: center;
        }
      }

    }

    td {
      &:nth-child(5) {
        .listing-row-primary-text {
          text-align: left;
        }
      }
    }

    .ant-table-row-selected {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
      border-radius: 15px;

      td {
        border-top: 3px solid $magenta-color;
        border-bottom: 3px solid $magenta-color !important;

        &:nth-of-type(2) {
          border-left: 3px solid $magenta-color;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;

        }

        &:nth-of-type(7) {
          border-right: 3px solid $magenta-color;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }

      }
    }

    &:not(.listing-row-secondary-text) {
      color: #272727;
      font-size: 16px;
      font-weight: 500;
    }

    .listing-row-secondary-text {
      font-weight: 400;
    }

    > tr.ant-table-row-hover {
      td {
        background: white !important;
      }
    }

    > tr {
      cursor: unset !important;

      &:hover {
        td {
          background: white !important;
        }
      }
    }
  }

  .ant-table-thead {
    background: $athens-gray;

    th {
      font-size: 12px;
      color: $light-gray-color3;
      font-weight: 500;
      text-transform: uppercase;
      padding: 16px 10px;
    }

    th:nth-of-type(3) {
      width: 15%;
    }

    th:nth-of-type(4) {
      width: 20%;
    }

    > tr.ant-table-row-hover {
      td {
        background: white !important;
      }
    }

    > tr {
      cursor: unset !important;

      &:hover {
        td {
          background: white !important;
        }
      }
    }
  }
}

.admin-batches-teacher-listing {
  .ant-btn-primary {
    background-color: $magenta-color;
  }

  .export-btn {
    background-color: $magenta-color;
    border-radius: 10px;
    box-shadow: $shadowType1;
  }

  .outline {
    background-color: $white-color;
    color: $magenta-color;
    border: 1px solid #9351CD;
    width: auto;
    margin: 0 15px;
    &:hover{
      background-color: unset;
      border: 1px solid #9351CD;
      width: auto;
    }
  }
}

.ant-btn {
  text-transform: capitalize;
}

.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary:active,
.ant-btn-primary:visited {
  background-color: $honey-flower;
  border: none;
  min-width: 130px;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.ant-btn-md,
.ant-btn-md:hover,
.ant-btn-md:focus,
.ant-btn-md:active,
.ant-btn-md:visited {
  height: 48px;
  width: 144px;
}

.ant-btn-default,
.ant-btn-default:hover,
.ant-btn-default:focus,
.ant-btn-default:active,
.ant-btn-default:visited {
  background-color: $light-gray-color;
  border: none;
  min-width: 130px;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  color: $default-text-color;
}
