@import 'assets/stylesheet/variables.scss';

.pricing-container {
  .login-container {
    height: 100%;
    width: 100%;
    padding: 5.6rem 0;
    background-image: linear-gradient(359deg, #552d83, #432870 64%, #361e5f);
    display: flex;
    align-items: center;
    overflow-y: auto;

    .left-part {
      text-align: center;
      padding: 0 8%;
      flex: 0.55;

      .user-illustration {
        margin-bottom: -90px;
        user-select: none;
        -webkit-user-drag: none;
      }

      .title {
        font-size: 37px;
        font-weight: 500;
        color: #ffffff;
        margin-top: 24px;
      }

      h4 {
        margin-top: 0px!important;
        font-size: 25px!important;
      }

    }

    .right-part {
      flex: 0.45;
      padding: 0 96px;

      .mobile-logo {
        display: none;
      }

      .gradient-text {
        background-image: radial-gradient(circle at 50% 3%, #e6b815, #ff8a23);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        object-fit: contain;
        font-size: 3rem;
        font-weight: 500;
      }

      .helper-text {
        font-size: 1.5rem;
        color: #ffffff;
        opacity: 0.8;
      }

      .pricing-button {
        background-color: $theme-btn-color;
        color: #fff;
        font-size: 16px;
        border: none;
        font-weight: 500;
        border-radius: 12px;
        padding: 0.7rem 2rem;
        cursor: pointer;
      }
    }

    @media screen and (min-width: 1024px) and (max-width: 1272px) {
      .right-part {
        padding: 0 24px;
      }

    }

    @media screen and (max-width: 1024px) {
      background-image: $theme-gradient;

      .left-part {
        display: none;
      }

      .input-focused {
        margin-top: 100px;
      }

      .right-part {
        width: 100%;
        padding: 1rem;
        flex: 1;
        text-align: center;
        
        .mobile-logo {
          display: block;
          position: relative;
          top: -2rem;
          left: 50%;
        }

        .gradient-text {
          font-size: 2.7rem;
          text-align: center;
        }

        .helper-text {
          font-size: 1.2rem;
        }
      }
    }
  }

  .pricing-table-container {
    background: #e9e8f1;
    padding: 4rem 0;
    .pricing-table-header {
      text-align: center;
      padding: 0 1rem;
      font-size: 1.8rem;
      margin: 0;
    }

    .pricing-table-subheader {
      text-align: center;
      margin: 0 0 1rem;
    }

    .navButton {
      position: absolute;
      left: 2rem;
      cursor: pointer;
      background: transparent;
      border: none;
    }

    .container {
      margin: 0 auto;
      padding: 2rem 0;
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .panel {
        border-radius: 10px;
        position: relative;
        width: 100%;
        z-index: 10;
      }

      .pricing-table {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin: auto 0;

        * {
          text-align: center;
          text-transform: uppercase;
        }

        .pricing-plan {
          border-bottom: 1px solid #e1f1ff;
          padding: 25px;
          border-radius: 12px;
          margin: 0 1rem;
          max-width: 300px;
          height: fit-content;
          background: white;
          box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.08), 0px 20px 31px 3px rgba(0, 0, 0, 0.09), 0px 8px 20px 7px rgba(0, 0, 0, 0.02);

          &:last-child {
            border-bottom: none;
          }

          .pricing-img {
            margin-bottom: 10px;
            max-width: 100%;
          }

          .pricing-header {
            font-weight: 500;
            letter-spacing: 1px;
            text-align: right;
            margin: 15px 10px 10px 0;
            color: gray;
            font-size: 1.3rem;
            
            &:after {
              content: "";
              display: block;
              padding-top: 2px;
              width: 25%;
              margin: 0 auto;
              border-bottom: 2px solid darkgray; 
            }
            
          }

          .pricing-base-days {
            text-transform: none;
            margin-top: -0.5rem;
            color: gray
          }

          .pricing-features {
            font-weight: 600;
            letter-spacing: 1px;
            margin: 15px 0;
            list-style: none;
            padding: 0 1rem;
          }

          .pricing-features-item {
            font-size: 15px;
            line-height: 1.1;
            padding: 15px 0;
            text-align: left;
            text-transform: capitalize;
            
            svg {
              fill: green;
            }
          }

          .pricing-price {
            color: #ff8a23e0;
            display: block;
            font-size: 30px;
            margin-bottom: 0;
            span {
              font-size: 42px;
              font-weight: 600;
              padding-top: 5px;
            }
          }

          .pricing-button {
            background-color: $theme-btn-color;
            color: #fff;
            font-size: 16px;
            border: none;
            font-weight: 500;
            border-radius: 15px;
            padding: 0.5rem 2rem;
            cursor: pointer;

            &:focus {
              outline: none;
            }
          }
        }

        @media (min-width: 900px) {
          .pricing-plan {
            border-bottom: none;
            border-right: 1px solid #e1f1ff;
            flex-basis: 100%;
            padding: 10px 20px;

            &:last-child {
              border-right: none;
            }
          }
        }
      }

      @media (min-width: 900px) {
        .pricing-table {
          flex-direction: row;
        }
      }

      .right-part {
        .mobile-logo {
          display: none;
        }

        flex: 0.45;
        padding: 0 96px;

        .login-card {
          border-radius: 6px;
          box-shadow: 0 22px 124px 0 rgba(0, 0, 0, 0.22);
          background-color: #ffffff;
          padding: 60px 56px;
          width: 400px;
          margin: 1rem auto;

          .change-contact-btn {
            text-align: right;
            position: relative;
            top: -1rem;
            cursor: pointer;
          }

          .heading {
            font-size: 32px;
            font-weight: 500;
          }

          .sub-heading {
            opacity: 0.5;
            font-size: 20px;
            color: #181e3b;
            margin-bottom: 28px;
          }

          button {
            background-color: $theme-btn-color;
            color: #fff;
            height: 52px;
            font-size: 16px;
            border: none;
            margin-top: 16px;
            font-weight: 500;
            border-radius: 6px;
            margin-right: 16px;
          }

          button:disabled {
            background-color: $theme-btn-color-disabled;
          }

          a {
            color: $theme-color;
            font-weight: 600;
            cursor: pointer;
          }

          .label {
            font-size: 16px;
            margin-bottom: 8px;
            font-weight: 400;
          }

          .childcard {
            background: #e8e7f0;
            padding: 1rem;
            border-radius: 5px;
            margin: 0.5rem 0;

            .bottom-childcard {
              display: flex;
              justify-content: space-between;

              p {
                color: red;
                margin: 0;
              }

              .pricing-button {
                height: auto;
                margin: 0;
              }
            }

            .promo-input {
              h4 {
                margin: 0;
              }

              .promo-input-container {
                border: 1px solid lightgray;
                width: fit-content;
                padding: 0 0.5rem;
                margin: 1rem 0 0;
                background: white;
                border-radius: 5px;
                
                .input-field {
                  border: 0;
                  padding: 7px 15px 7px 0;
                  position: relative;
                  background: transparent;
                  width: 150px;

                  &:focus {
                    outline: none;
                  }
                }

                .promo-input-button {
                  background: white;
                  color: #2d2d2d;
                  font-size: 0.9rem;
                  height: auto;
                  margin: 0;
                  cursor: pointer;
                }
              }

              p {
                color: green
              }
            }
          }

          .selected-child {
            background: radial-gradient(circle at 80% 9%, #e6b815, #ff8a23) !important;
            box-shadow: 5px 5px 10px lightgrey !important;
          }
        }
      }

      @media screen and (min-width: 1024px) and (max-width: 1272px) {
        .right-part {
          padding: 0 24px;

          // flex: 0.55;
          .login-card {
            padding-left: 20px;
            padding-right: 20px;
            
          }
        }

      }

      @media screen and (max-width: 340px) {
        .right-part {
          .login-card {
            width: fit-content !important;
          }
        }
      }

      @media screen and (max-width: 1024px) {
        .right-part {
          width: 100%;
          padding: 0;
          flex: 1;

          .mobile-logo {
            display: block;
            position: absolute;
            top: 40px;
            left: 50%;
            transform: translateX(-50%);
          }

          .login-card {
            padding: 45px 20px;
            width: 300px;
            margin: 0 auto;

            .heading {
              font-size: 25px;
            }

            .ant-form {
              color: #fff;
            }

            .sub-heading {
              opacity: 1;
            }

            a {
              color: #fff;
            }

            input {
              color: rgba(0, 0, 0, 0.65);
            }

            .bottom-childcard {
              flex-direction: column;
            }

            .input-field {
              width: 150px !important;
              padding: 5px !important;
            }
          }
        }

        .pricing-table {
          .pricing-plan {
            width: 100%;
            margin: 3rem 0;
          }
        }
      }
    }

    @media (min-width: 900px) {
      .container {
        flex-direction: row;
        width: 80%;
      }
    }
  }

  .bottom-container {
    background-image: linear-gradient(359deg, #552d83, #432870 64%, #361e5f);
    padding: 2rem 0 0; 
    
    .bottom-container-card {
      background: white;
      width: 60%;
      margin: 0 auto;
      padding: 2rem 1rem;
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
      height: fit-content !important;

      .left-part {
        .pricing-footer-img {
          width: 200px;
          position: relative;
          bottom: -2rem;
        }
      }

      .right-part {
        flex: 1 1 !important;
        padding: 0 56px ;
        margin: auto 0;

        h3 {
          font-size: 1.5rem;
          color: $theme-btn-color;
        }
        
        .pricing-button {
          background-color: $theme-btn-color;
          color: #fff;
          font-size: 16px;
          border: none;
          font-weight: 500;
          border-radius: 6px;
          padding: 0.5rem 1rem;
          cursor: pointer;
        }
      }

      @media screen and (max-width: 1024px) {
        .left-part, .right-part {
          flex: 1 !important;
          width: 100%
        }
  
        .left-part {
          display: block
        }
  
        .right-part {
          padding: 0 !important;
        }
      }
    }

    @media screen and (max-width: 1024px) {
      .bottom-container-card {
        flex-direction: column;
        width: 75% !important;
      }
    }
  }
}