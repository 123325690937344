.batch-details {
  transition: all 0ms ease !important;

  .header {
    margin-left: 15px;
  }

  .back-btn {
    color: #524E59;
    font-weight: 500;
    border: none;
    padding: none;
    margin: none;
    margin-left: -12px;
    font-size: 16px;
    width: auto;
    height: auto;
  }

  .container {
    width: 100%;
    display: flex;
    flex-direction: row;

    .main {
      flex-grow: 1;
      background-color: white;

      .ant-tabs-bar {
        border: none;
        margin: 0 0 5px 0;
      }

      .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
        height: 75px;
        transition: unset !important;

        .ant-tabs-nav {
          display: flex;
        }

        .ant-tabs-nav-animated {
          div:first-child {
            display: flex;
            align-items: center;
            flex: 1;
          }
        }
      }

      .card-container p {
        margin: 0;
      }

      .card-container > .ant-tabs-card .ant-tabs-content {
        margin-top: -18px;
        margin-left: 5px;
      }

      .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
        background: #fff;
      }

      .card-container > .ant-tabs-card > .ant-tabs-nav::before {
        display: none;
      }

      .card-container > .ant-tabs-card .ant-tabs-tab,
      [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
        border-color: transparent;
        background: #fff;
        margin: 2px 10px 20px 20px;
        color: #999999;
        font-weight: 500;
        font-size: 16px;
        width: 45%;
        transition: unset !important;
        text-align: center;
        justify-content: center;

        path, svg {
          fill: #999999;
        }
      }

      .card-container > .ant-tabs-card .ant-tabs-tab-active,
      [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
        border-color: #fff;
        background: #fff;
        box-shadow: 0px -6px 15px rgba(0, 0, 0, 0.07);
        height: 60px;
        margin: 2px 10px 20px 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        color: black;
        width: 45%;
        transition: unset !important;
        justify-content: center;
        text-align: center;

        path, svg {
          fill: #000;

        }
      }

      #components-tabs-demo-card-top .code-box-demo {
        background: #f5f5f5;
        overflow: hidden;
        padding: 24px;
      }

      [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-content {
        height: 120px;
        margin-top: -8px;
      }

      [data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab {
        border-color: transparent;
        background: transparent;
      }

      [data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
        background: #000;
      }

      [data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
        background: #141414;
      }

      [data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
        border-color: #141414;
        background: #141414;
      }
    }

    .sidebar {
      padding: 1px 0 0 0;
      width: 400px;
      background-color: white;
    }
  }

}
