@import "./variables.scss";

.primary-btn {
  background-color: $theme-btn-color;
  color: #fff;
  font-size: 16px;
  border: none;
  font-weight: 500;
  border-radius: 6px;
}
.primary-btn:focus {
  background-color: $theme-btn-color;
}
.primary-btn:hover {
  background-color: $theme-btn-color-hover;
}

.outline-btn {
  background-color: transparent;
  color: $theme-btn-color;
  font-size: 16px;
  border: 1px solid $theme-btn-color;
  font-weight: 500;
  border-radius: 6px;
}

.outline-btn:focus {
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
}
.outline-btn:hover {
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
}

// display
.flex {
  display: flex;
}
//direction
.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-col {
  flex-direction: column;
}
.flex-col-reverse {
  flex-direction: column-reverse;
}
//wrap
.flex-wrap {
  flex-wrap: wrap;
}
//align-items
.items-start {
  align-items: flex-start;
}
.items-center {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
// justify-content
.justify-start {
  justify-content: flex-start;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.text-white {
  color: #fff;
}
.text-12 {
  font-size: 12px;
}

.ant-modal-mask {
  background: rgba(0, 0, 0, 0.92);
}

.icon-text {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-right: 3px;
  }
}
.content-box {
  .title {
    color: $magenta-color;
  }
  p {
    margin-top: 20px;
    font-weight: 500;
    margin-bottom: 5px;

    &.content {
      color: #524e59;
    }
  }
}

@media (max-width: $max-resp-width) {
  .ant-modal-mob {
    .ant-modal-content {
      position: fixed;
      bottom: auto;
      left: auto;
      top: 0px;
      right: 0px;
      width: 100%;
      height: 100%;
    }
  }
  .mob-modal-type-1 {
    * {
      transition: none !important;
      animation: none !important;
    }
    .ant-modal-content {
      position: fixed;
      bottom: 0px;
      left: 0px;
      top: auto;
      right: auto;
      width: 100%;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
    .ant-modal-header {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
  }

  .mob-modal-type-2 {
    * {
      transition: none !important;
      animation: none !important;
    }
    .ant-modal-content {
      background-color: #e5e5e5 !important;
      position: fixed;
      bottom: auto;
      left: auto;
      top: 0px;
      right: 0px;
      width: 100%;
      height: 100%;
      background-color: #ebebeb !important;
      .ant-modal-close-x {
        visibility: hidden;
        position: fixed;
        left: -15px;
        top: 10px;
        &::after {
          content: "<";
          color: black;
          z-index: 999;
          width: 10px;
          height: 10px;
          visibility: visible;
          font-size: 25px;
          font-weight: 300;
          position: absolute;
          top: -5px;
          left: 31px;
        }
      }
      .ant-modal-header {
        padding: 60px 15px 22px 15px !important;
        border-bottom-left-radius: 15px !important;
        border-bottom-right-radius: 15px !important;

        .ant-modal-title {
          font-style: normal;
          font-weight: 500;
          font-size: 32px;
          line-height: 26px;
          color: #000;
        }
      }
      .ant-modal-body {
        background-color: #fff;
      }
    }
  }
}

@media (max-width: $max-resp-width) {
  .mobile-column {
    flex-direction: column !important;
    padding-bottom: 20px;
  }
  .m-width-100 {
    width: 100% !important;
  }
  .m-display-none {
    display: none !important;
  }
  .mobile-page-header {
    width: 100% !important;
    top: 0px;
    left: 0px;
    background-color: white;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}
.display-desk-none {
  display: none;
  @media (max-width: $max-resp-width) {
    display: block;
  }
}

.seperator-20 {
  width: 100%;
  height: 1px;
  background: none;
}
.seperator-10 {
  margin-bottom: 10px;
  width: 100%;
  height: 1px;
  background: none;
}
.no-footer-modal {
  .ant-modal-footer {
    display: none;
  }
}
.no-header-modal {
  .ant-modal-close {
    display: none !important;
  }
  .ant-modal-header {
    display: none !important;
  }
}
.no-padding-modal {
  .attendance-modal {
    .ant-modal-body {
      padding: 0px !important;
    }
  }
  .ant-modal-body {
    padding-left: 0px !important;
    padding-bottom: 0px !important;
  }
}
