@import "assets/stylesheet/variables.scss";

.student-details-sidebar {
  //   display: none;
  width: 341px;
  padding: 40px 24px;
  border-right: 1px solid #ececec;

  .student-img {
    width: 96px;
    height: 96px;
    border-radius: 100px;
    object-fit: cover;
    margin: auto;
  }

  .student-name {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    margin-top: 16px;
    color: #524e59;
  }

  .grade-info {
    width: 100%;
    margin-top: 12px;
    justify-content: center;
    display: flex;

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 17.2938px;
      line-height: 20px;
      color: #9134d8;
      margin-left: 5px;
    }
  }

  .details-fields {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    width: 100%;

    .detail {
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        flex: 1;
        padding: 8px 13px;

        &:nth-child(1) {
          text-align: left;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          /* identical to box height */

          /* Text Grey */

          color: #524e59;

          opacity: 0.3;
        }

        &:nth-child(2) {
          text-align: right;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          /* identical to box height */

          /* Text Grey */

          color: #524e59;
        }
      }

      .edit-icon {
        cursor: pointer;
        border-radius: 100px;
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;

      }
    }
  }

  .seperator-line {
    background: #ececec;
    border-radius: 8px;
    height: 1px;
    width: 100%;
    margin-top: 27px;
    margin-bottom: 27px;
  }

  .attendance-info {
    display: flex;
    justify-content: space-between;

    & > div {
      h5 {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #524e59;

        span {
          margin-left: 5px;
        }
      }

      h3 {
        margin-top: 16px;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        display: flex;
        align-items: center;

        /* #9351CD */

        color: #9351cd;
      }
    }
  }

  .teachers-comment {
    margin-top: 40px;

    h5 {
      display: flex;
      align-items: center;

      span {
        margin-left: 5px;
      }
    }

    .comment-box {
      background: #ffffff;
      border: 1px solid rgba(82, 78, 89, 0.1);
      box-sizing: border-box;
      border-radius: 8px;
      padding: 16px;

      .comment-text {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 120%;
        /* or 17px */

        display: flex;
        align-items: center;

        /* Text Grey */

        color: #524e59;
        margin-bottom: 35px;
      }

      .comment-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          color: #88868b;
        }

        .teacher-information {
          display: flex;
          justify-content: flex-start;

          img {
            width: 38px;
            height: 38px;
            border-radius: 100%;
          }

          h5 {
            display: flex;
            align-items: center;
            margin-left: 5px;
            margin-top: 5px;
            margin-bottom: 0px;
            font-family: Rubik;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 17px;
            /* identical to box height */

            /* Text Grey */

            color: #524e59;
          }

          h6 {
            margin-left: 7px;
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 14px;
            display: flex;
            align-items: center;

            /* Text Grey */

            color: #524e59;
          }
        }
      }
    }
  }
}
