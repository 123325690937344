@import 'assets/stylesheet/variables.scss';

.batch-details-card {
  height: 400px;
  display: flex;
  flex-direction: row;
  width: 90%;
  transition: box-shadow 300ms ease-in-out;
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;
  background-color: white;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  //margin: auto;

  .card-mobile-content {
    display: none;
  }

  .spacer {
    width: 100%;
    height: 20px;
  }

  .card-sidebar {
    height: 50px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-top: 24px;
    width: 5px;
    background-color: rgba(244, 113, 43, 1);
  }

  .card-content {
    padding: 30px 32px;
    flex-grow: 1;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    .timings {
      color: rgba(82, 78, 89, 0.8);
      font-size: 15px;
      font-weight: 500;
      display: flex;
      align-items: center;

      svg {
        margin-right: 8px;
      }
    }

    .classes-container {
      .title {
        font-weight: 500;
        color: rgba(82, 78, 89, 0.8);
        font-size: 15px;
      }

      .value {
        font-weight: 500;
        width: 100%;
        margin-left: 20px;
      }
    }

    .grades {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-weight: 500;
      color: $magenta-color;

      > div {
        margin-right: 5px;
      }

      .dot {
        border-radius: 1000px;
        width: 5px;
        height: 5px;
        background-color: rgba(51, 51, 51, 1);
      }
    }

    .card-footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      margin-top: 30px;
      cursor: pointer;

      .student-images {
        display: flex;
        flex-direction: row;
        align-items: center;

        .card-profile-image {
          width: 50px;
          height: 50px;
          border-radius: 100px;
          border: 4px solid white;
          object-fit: cover;
          background: white;
        }

        .more {
          margin-left: -15px;
          width: 50px;
          height: 50px;
          border: 5px solid white;
          background-color: rgba(236, 236, 236, 1);
          color: rgba(82, 78, 89, 1);
          border-radius: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
        }
      }

      .arrow {
        font-weight: 500;
      }
    }

  }

  &.batch-card-mobile {
    display: none;
    @media(max-width: $max-resp-width) {
      display: block;
      margin-bottom: 30px;
      padding-top: 40px;
      border-radius: 15px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;

      .card-mobile-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 25px;

        & > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        h1 {
          span {
            padding: 0px 3px;
          }
        }
        h5 {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          /* identical to box height, or 133% */

          text-transform: uppercase;

          /* Text Grey */

          color: #524E59;
        }
      }
      .card-sidebar {
        display: none;
      }
      .card-content {
        padding: 5px 18px;

        h2 {
          font-size: 32px;
        }
        .grades {
          * {
            font-size: 18px;
          }
          padding-bottom: 40px;
        }
        .card-footer {
          display: none;
        }
        .classes-container {
          display: none;
        }
        .timings {
          display: none;
        }
        p {
          display: none;
        }
      }
    }
  }
}
