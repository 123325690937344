@import "assets/stylesheet/variables.scss";

$shadowType1: 0px 0px 14px rgba(0, 0, 0, 0.10);

.teacher-listing-section {
  border-radius: 6px 6px 0 0;
  background-color: $white-color;
  height: 80vh;

  .ant-table-thead {
    background: $athens-gray;

    th {
      font-size: 12px;
      color: $light-gray-color3;
      font-weight: 500;
      text-transform: uppercase;
      padding: 16px 10px;
    }
  }

  .ant-table-placeholder {
    border-top: none;
    border-bottom: none;
    background: transparent;
  }

  .ant-table .ant-table-tbody {
    > tr {
      cursor: pointer;

      > td {
        vertical-align: middle;
        border-bottom: none;
      }
    }

    > tr:hover {
      box-shadow: 1px 4px 15px 1px rgba(0, 0, 0, 0.11);

      > td {
        background: white !important;
      }
    }

    &:before {
      content: "@";
      display: block;
      line-height: 10px;
      text-indent: -99999px;
    }
  }

  .ant-pagination {
    padding: 16px;
    text-align: right;
  }
}

.filter-count {
  display: flex;
  align-items: center;

  span {
    width: 22px;
    height: 22px;
    font-size: 12px;
    background-color: #9134D8;
    border-radius: 100px;
    margin-left: 20px;
    color: white;
    display: inline-block;
    text-align: center;
  }
}

.teacher-listing {
  &-header {
    display: flex;
    flex-direction: row;

    .ant-input {
      width: 290px;
      height: 48px;
      font-size: 16px;
      color: $default-text-color;
      border-radius: 8px;
      background: $white-color url("../../../../assets/image/search.svg") no-repeat 14px;
      border: solid 1px $iron-color;
      padding-left: 50px;
      outline: none;

      &:hover,
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .ant-dropdown-trigger {
      width: 180px;
      height: 48px;
      font-size: 16px;
      line-height: 48px;
      color: $default-text-color;
      border: solid 1px $iron-color;
      border-radius: 8px;
      background: $white-color;
      padding: 0 16px;
    }

    .export-btn {
      background-color: $magenta-color;
      border-radius: 10px;
      box-shadow: $shadowType1;
    }

    .ant-collapse {
      background-color: transparent;
      border: none;

      &-content {
        border: none;
        max-height: 40vh;
        overflow-y: auto;
      }

      &-content-box {
        padding: 10px 0 0 0;
      }

      > .ant-collapse-item {
        border: none;

        > .ant-collapse-header {
          padding: 12px 0;
          font-size: 20px;
          color: $default-text-color;
          font-weight: 500;
          border-bottom: solid 1px $iron-color;
          border-radius: 0;
        }
      }

      > .ant-collapse-item:last-child {
        > .ant-collapse-header {
          border-bottom: none;
        }
      }
    }

    .ant-checkbox-wrapper {
      min-height: 15px;
      margin: 0;
      padding: 6px;
      line-height: normal;
      font-size: 16px;
      color: $default-text-color;
      width: 100%;
    }

    .ant-checkbox:after {
      display: none;
    }

    .ant-checkbox-inner,
    .ant-checkbox-inner:hover {
      height: 20px;
      width: 20px;
      box-sizing: border-box;
      border: solid 2px;
      border-color: $magenta-color !important;
      border-radius: 6px;
      margin-right: 6px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $magenta-color;

      &:after {
        left: 28%;
        border-width: 1px;
        top: 45%;
      }
    }
  }
}

.listing-row {
  &-profile-pic {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    background: $light-gray-color3;
    display: inline-block;
    margin: 0;
    overflow: hidden;
    vertical-align: middle;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    img {
      height: 100%;
    }
  }

  &-primary-text {
    font-size: 16px;
    font-weight: 500;
    color: $default-text-color;
    margin: 3px 0;
    // text-transform: capitalize;
  }

  &-secondary-text {
    font-size: 14px;
    color: $default-text-color;
    margin: 3px 0;
  }

  &-class-type {
    background: $magenta-color;
    border-radius: 4px;
    line-height: 20px;
    padding: 0 10px;
    font-size: 12px;
    font-weight: 500;
    color: $white-color;
    margin: 0 3px;
    display: inline-block;

    &--red {
      background: $flamingo;
    }
  }

  &-status {
    font-size: 0;
    width: 36px;
    height: 14px;
    border-radius: 10px;
    background: $light-gray-color3;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    position: relative;

    &:after {
      content: "";
      width: 21px;
      height: 21px;
      box-shadow: 0 1px 1.9px 0 rgba(0, 0, 0, 0.25);
      background: $white-color;
      position: absolute;
      top: -4px;
      left: -2px;
      border-radius: 11px;
    }

    &--active {
      background: #e9d6f7;

      &:after {
        background-color: #9134d8;
        right: -2px;
        left: auto;
      }
    }
  }
}

#teacherListingFilter {
  position: relative;
  margin: 0 16px 0 8px;
  cursor: pointer;
}

.ant-btn {
  text-transform: uppercase;
}

.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary:active,
.ant-btn-primary:visited {
  background-color: $honey-flower;
  border: none;
  min-width: 130px;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.ant-btn-md,
.ant-btn-md:hover,
.ant-btn-md:focus,
.ant-btn-md:active,
.ant-btn-md:visited {
  height: 48px;
  width: 144px;
}

.ant-btn-default,
.ant-btn-default:hover,
.ant-btn-default:focus,
.ant-btn-default:active,
.ant-btn-default:visited {
  background-color: $light-gray-color;
  border: none;
  min-width: 130px;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  color: $default-text-color;
}
