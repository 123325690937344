@import 'assets/stylesheet/variables.scss';

.error-text {
  color: red;
  text-transform: none;
  margin-top: 5px;
}

.component-grade-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(82, 78, 89, 0.5);

  // &__ant-switch {
  //   right: 24px;
  //   width: 33px;
  //   height: 13px;
  //   border-radius: 30.7px;

  //   &::after {
  //     background-color: #9135d8;
  //     width: 19px;
  //     height: 19px;
  //     box-shadow: 0 1.3px 2.6px 1.3px rgba(0, 0, 0, 0.25);
  //     top: -4px;
  //   }
  // }

  &__button {
    margin-top: 48px;
    border-radius: 8px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.11);
    background-color: #9134d8;
    border: none;
    width: 240px;
    height: 56px;
    font-family: 'Rubik';
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    color: #ffffff;

    &:hover,
    &:focus,
    &:visited {
      background: $honey-flower;
      color: $white-color;
    }
  }

  &__close-button {
    position: absolute;
    top: 20px;
    right: 24px;
    cursor: pointer;
  }

  &__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.11);
    border: solid 0 #979797;
    background-color: #ffffff;
    padding: 48px;
    width: 620px;
    height: 71.3%;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__section {
    padding: 32px 0;
    border-bottom: 2px solid #ececec;

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }

    &--text {
      font-family: 'Rubik';
      font-size: 20px;
      font-weight: 500;
      line-height: normal;
      color: #524e59;
    }

    &--title-text {
      font-family: 'Rubik';
      font-size: 14px;
      font-weight: 500;
      line-height: 0.71;
      color: #9134d8;
      margin-bottom: 24px;
    }
  }

  &__title-text {
    font-family: 'Rubik';
    font-size: 28px;
    font-weight: 500;
    line-height: normal;
    color: #524e59;
  }
}

.form {
  &__column {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__control {
    display: flex;
    flex-direction: column;

    &--input {
      border-radius: 8px;
      box-shadow: 0 4px 34px 0 rgba(0, 0, 0, 0.06);
      border: solid 0 #979797;
      background-color: #ffffff;
      font-family: 'Rubik';
      font-size: 20px;
      font-weight: 500;
      line-height: 0.8;
      color: #524e59;
      height: 57px;
      width: 254px;
      padding: 0 16px;
      outline: none;
    }

    &--label {
      font-family: 'Rubik';
      font-size: 14px;
      font-weight: 500;
      line-height: 0.71;
      color: #979797;
      margin-bottom: 8px;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    margin-top: 24px;
  }
}

.w-full {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.ant-select-selection--single {
  height: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 34px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  padding: 16px;
  border: none;
}

.ant-select-selection-selected-value {
  font-family: 'Rubik';
  font-size: 20px;
  font-weight: 500;
  color: #524e59;
  // line-height: 1;
}

.ant-select-selection__rendered {
  height: 100%;
  margin: 0;
  display: flex;
  align-items: center;
}

.ant-select-arrow {
  right: 16px
}
