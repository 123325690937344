@import "assets/stylesheet/variables.scss";

.admin-batches-listing-table {
  .d-flex {
    flex-direction: row;
    display: flex;
    align-items: center;
  }

  .j-c-c {
    justify-content: center;
  }

  .grades-container {
    * {
      margin-right: 10px;
    }
  }

  .ant-table-tbody {
    * {
      border: none;
      font-family: Rubik;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;

      &:not(.pill) {
        color: #524e59;
      }

      .pill {
        font-size: 13px;
        padding: 6px 10px;
      }
    }

    &:before {
      content: "@";
      display: block;
      line-height: 10px;
      text-indent: -99999px;
    }

    > tr.ant-table-row-hover {
      td {
        background: white !important;
      }
    }

    > tr {
      &:hover {
        td {
          background: white !important;
        }
      }
    }

    tr {
      :nth-child(1),
      :nth-child(2),
      :nth-child(3),
      :nth-child(4),
      :nth-child(5) {
        cursor: pointer;
      }

      td {
        text-align: center;
      }

      &:hover {
        box-shadow: 1px 4px 15px 1px rgba(0, 0, 0, 0.11);
      }
    }

    .dot {
      border-radius: 1000px;
      width: 5px;
      height: 5px;
      background-color: rgba(51, 51, 51, 1);
    }

    .student-images {
      display: flex;
      flex-direction: row;
      align-items: center;

      .card-profile-image {
        width: 50px;
        height: 50px;
        border-radius: 100px;
        border: 4px solid white;
        object-fit: cover;
        background: white;
      }

      .more {
        margin-left: -15px;
        width: 50px;
        height: 50px;
        border: 5px solid white;
        background-color: rgba(236, 236, 236, 1);
        color: rgba(82, 78, 89, 1);
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }
    }

    .btn-outline-purple {
      border: 2px solid $magenta-color;
      color: $magenta-color;
      font-weight: bold;
      width: auto;
      height: auto;
      padding: 5px 25px;
      border-radius: 5px;

      span {
        color: $magenta-color;
        font-size: 12px;
      }
    }
  }

  .ant-table-thead {
    th {
      font-size: 12px;
      color: $light-gray-color3 !important;
      font-weight: 500;
      text-transform: uppercase;
      padding: 16px 10px;
      border-bottom: 1px solid #e8e8e8;
    }

    tr {
      th {
        text-align: center;
      }
    }

    > tr.ant-table-row-hover {
      td {
        background: white !important;
      }
    }

    > tr {
      &:hover {
        td {
          background: white !important;
        }
      }
    }
  }
}

.admin-teacher-list {
  .ant-radio-group {
    width: 100%;
  }
}
.form-control {
  height: 100%;
  border: white;
  border-radius: 10px;
  box-shadow: 0px 0px 14px rgba(black, 0.1);
  margin-right: 1em;
}
.Container {
  position: relative;
  .autoContainer {
    position: absolute;
    /* bottom: 0px; */
    background: white;
    z-index: 1;
    width: 290px;
    .options {
      padding: 0.5em 1em;
    }
  }
}
.rdt {
  margin-right: 2em;
}
#batches {
  margin-right: 2em;
}
.selectfilter .ant-select-selection {
  width: 250px !important;
  margin-right: 1em;
  height: 47px;
}
.searchoutlined {
  background-color: #1890ff;
  height: 24px;
  color: white;
}
.teacher-listing-header {
  .inputs .ant-input,
  .ant-input:focus,
  .ant-input:hover,
  .ant-input:active,
  .ant-select-selection,
  .ant-input-number {
    height: 47px;
    width: 270px;
  }
  .teacherinputs .ant-input,
  .ant-input:focus,
  .ant-input:hover,
  .ant-input:active,
  .ant-select-selection,
  .ant-input-number {
    height: 47px !important;
    width: 250px !important;
  }
  .studentinputs .ant-input,
  .ant-input:focus,
  .ant-input:hover,
  .ant-input:active,
  .ant-select-selection,
  .ant-input-number {
    height: 47px !important;
    width: 250px !important;
  }
}
