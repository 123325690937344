@import "assets/stylesheet/variables.scss";

$grey-color: #524e59;

.preference-create-form {
  .weekday-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;

    li {
      cursor: pointer;
      height: 56px;
      width: 88px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #979797;
      font-size: 16px;
      font-weight: 500;
      border: none;
      border-radius: 8px;
      margin: 0 16px 0 0;
      background: rgba(236, 236, 236, 0.2);

      &.disabled {
        cursor: not-allowed;
      }

      &.active {
        border: solid 2px $magenta-color;
      }
    }
  }
}
