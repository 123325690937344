
.learning-outcome-content {
  color: #7D7A83;
  font-size: 15px;

  .content {
    color: #524E59;

    li {
      margin-top: 10px;
    }
  }
}
