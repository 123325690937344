@import "assets/stylesheet/variables.scss";
.dropdown_zoom_ID {
  width: 300px !important;
}
$grey-color: #524e59;
.root-dropdown {
  .ant-select-selection__placeholder {
    font-size: 16px;
    color: $default-text-color;
    opacity: 0.2;
    font-weight: 500;
  }
  .ant-select-selection-selected-value {
    font-size: 16px;
    font-weight: 500;
    color: $default-text-color;
  }

  .custom-dropdown {
    background-color: $white-color !important;
    border-radius: 0 !important;
    border: none;
    padding: 0;
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.06) !important;

    .ant-select-selection__rendered {
      padding: 0;
      margin: 0;
      line-height: 56px;
    }

    .ant-select-selection-selected-value {
      line-height: 56px;
    }

    .ant-form-item-children {
      display: block;
    }

    &:before {
      display: none;
      content: "";
      position: absolute;
      bottom: -20px;
      left: 0;
      background: $light-gray-color4;
      width: 100%;
      height: 20px;
    }

    &.ant-select-dropdown-placement-bottomLeft {
      border-radius: 0 0 20px 20px;

      &:before {
        top: -20px;
        bottom: auto;
      }
    }

    .ant-select-dropdown-menu {
      overflow-x: hidden;
    }

    .ant-select-dropdown-menu-item {
      font-size: 20px !important;
      font-weight: 500;
      color: $default-text-color;
      padding: 10px 16px;

      &:hover,
      &:active,
      &:focus,
      &-active {
        background-color: $magenta-color;
        color: $white-color;
      }
    }
  }

  .ant-select-arrow {
    color: #3c3d41;
    font-size: 11px;
  }
}
