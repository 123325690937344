@import "assets/stylesheet/variables.scss";

$shadowType1: 0px 0px 14px rgba(0, 0, 0, 0.1);
.ant-pagination {
  padding: 16px !important;
  text-align: right;
}

.student-listing-header {
  .ant-input {
    box-shadow: none !important;
    border: 1px solid rgba(151, 151, 151, 0.3) !important;

    &:hover {
      box-shadow: $shadowType1;
      border: none;
    }

    &:focus {
      box-shadow: $shadowType1;
      border: none;
    }
  }

  .ant-dropdown-trigger {
    box-shadow: none !important;
    border: 1px solid rgba(151, 151, 151, 0.3) !important;
    border: none;
  }

  .export-btn {
    background-color: $magenta-color;
    border-radius: 10px;
    box-shadow: $shadowType1;
  }
}

.student-listing-section {
  height: 80vh;

  .student-listing-table {
    .grade-container {
      display: flex;
      align-items: center;

      .dot {
        border-radius: 1000px;
        width: 5px;
        height: 5px;
        background-color: rgba(51, 51, 51, 1);
        margin: 5px;
      }
    }

    .ant-table-placeholder {
      border-top: none;
      border-bottom: none;
      background: transparent;
    }

    .ant-table-tbody {
      .pill {
        font-size: 13px;
        padding: 6px 10px;
      }

      td {
        border: none;
        font-family: Rubik;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #524e59;

        &:nth-child(1) {
          text-align: center;
        }

        &:nth-child(7) {
          text-align: center;
        }

        &:nth-child(8) {
          text-transform: capitalize;
        }
      }

      &:before {
        content: "@";
        display: block;
        line-height: 10px;
        text-indent: -99999px;
      }

      > tr.ant-table-row-hover {
        td {
          background: white !important;
        }
      }

      > tr {
        &:hover {
          td {
            background: white !important;
          }
        }
      }

      tr {
        &:hover {
          box-shadow: 1px 4px 15px 1px rgba(0, 0, 0, 0.11);
        }
      }
    }

    .ant-table-thead {
      th {
        font-size: 12px;
        color: $light-gray-color3 !important;
        font-weight: 500;
        text-transform: uppercase;
        padding: 10px 10px;
        border-bottom: 0px solid #e8e8e8;

        span {
          color: #524e59;
        }

        &:nth-child(6) {
          text-align: center;
        }

        &:nth-child(7) {
          text-align: center;
        }
      }

      > tr.ant-table-row-hover {
        td {
          background: white !important;
        }
      }

      > tr {
        &:hover {
          td {
            background: white !important;
          }
        }
      }
    }
  }

  .ant-btn {
    text-transform: uppercase;
  }

  .ant-btn-primary,
  .ant-btn-primary:hover,
  .ant-btn-primary:focus,
  .ant-btn-primary:active,
  .ant-btn-primary:visited {
    background-color: $honey-flower;
    border: none;
    min-width: 130px;
    height: 40px;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
  }

  .ant-btn-md,
  .ant-btn-md:hover,
  .ant-btn-md:focus,
  .ant-btn-md:active,
  .ant-btn-md:visited {
    height: 48px;
    width: 144px;
  }

  .ant-btn-default,
  .ant-btn-default:hover,
  .ant-btn-default:focus,
  .ant-btn-default:active,
  .ant-btn-default:visited {
    background-color: $light-gray-color;
    border: none;
    min-width: 130px;
    height: 40px;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    color: $default-text-color;
  }
}

// CSS for chose slot modal

.body-content-div {
  //height: 180px;
}

.radio-main-div {
  margin-top: 15px;
  display: grid;
  //FLV-161
  //height: 180px;
  height: 160px;
  //FLV-161
  overflow-y: scroll;
}

.radio-div {
  padding: 12px 10px 12px 10px;
}

.radio-div-border {
  border: 1px solid #9134d8;
  border-radius: 15px;
}

.radio-inner-div {
  display: flex;
  justify-content: space-around;
  // width: 370px;
}

.ant-radio-wrapper {
  display: flex;
  align-items: center;
}

.radio-span-day-text {
  color: #524e59;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  width: 150px;
  text-align: center;
}

.radio-span-text {
  color: #524e59;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  width: 100px;
  text-align: center;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #9134d8 !important ;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #9134d8 !important;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #9134d8 !important;
}

.ant-radio-checked .ant-radio-inner:focus {
  border-color: #9134d8 !important;
}

.listing-row-primary-text {
  margin: 0px !important;
}

.error-button-div {
  justify-content: space-between;
  display: flex;
  margin-top: 20px;
}
.error-div {
  width: 60%;
  color: red;
}