@import "assets/stylesheet/variables.scss";

$green-color: #27ae60;
$red-color: #f24e29;
$yellow-color: #f2bd1d;

.attendance-modal {
  font-family: "Rubik";
  height: 90%;
  overflow-y: hidden;
  margin: 10px 0;


  .add-feedback-btn {
    cursor: pointer;
    color: #9351cd;
    font-weight: 500;
  }

  .ml-20 {
    margin-left: 20px;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .d-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .options {
    @media (max-width: $max-resp-width) {
      display: none;
    }
    justify-content: flex-end;
    margin-right: 20px;

    h5 {
      margin: 0px;
      margin-left: 10px;
      padding: 0px;
    }

    margin-bottom: 10px;
  }

  .option-box {
    padding: 2px 20px;
    color: white;
    border-radius: 5px;

    .d-flex {
      * {
        margin: 100px;
      }
    }

    &.green {
      background-color: $green-color;
    }

    &.red {
      background-color: $red-color;
    }

    &.yellow {
      background-color: $yellow-color;
    }
  }

  .attendance-card-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    //justify-content: space-between;
    @media (max-width: $max-resp-width) {
      justify-content: center;
    }

    .row {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .attendance-card {
    width: 300px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 10px 20px;
    border: 1px solid rgba(151, 151, 151, 0.3);
    border-radius: 10px;
    background-color: #fff;
    @media (max-width: $max-resp-width) {
      padding-right: 0px;
      width: 100%;
      margin-right: 0px;
    }

    figure {
      margin-right: 20px;
    }

    h5 {
      color: #524e59;
    }
  }

  .ant-modal-header {
    display: block;
    background-color: white;
    padding: 40px 40px 20px 40px;
    border: none;
    @media (max-width: $max-resp-width) {
      padding: 60px 15px 22px 15px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }

    .ant-modal-title {
      .modal-header {
        display: flex;
        justify-content: space-between;
        padding-right: 20px;

        .title {
          color: #524e59;
          font-size: 24px;
          @media (max-width: $max-resp-width) {
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 26px;
            color: #000;
          }
        }

        .ant-btn-primary {
          background-color: $magenta-color;
          font-weight: 500;
          border-radius: 8px;
          color: $white-color;

          &:hover,
          &:focus,
          &:visited {
            background: $honey-flower;
            color: $white-color;
          }
        }
      }
    }
  }

  .ant-modal-content {
    background-color: white;
    color: #7d7a83;
    overflow-y: auto;
    top: -30px;
    height: 77vh;
    @media (max-width: $max-resp-width) {
      background-color: #ebebeb !important;
      color: #7d7a83 !important;
      padding-bottom: 50px !important;
      height: 100vh !important;
      position: fixed !important;
      top: 0px !important;
      left: 0px !important;
      width: 100vw !important;
      padding-top: 0px;
      .ant-modal-close-x {
        visibility: hidden;
        position: fixed;
        left: -15px;
        top: 10px;

        &::after {
          content: "<";
          color: black;
          z-index: 999;
          width: 10px;
          height: 10px;
          visibility: visible;
          font-size: 25px;
          font-weight: 300;
          position: absolute;
          top: -5px;
          left: 31px;
        }
      }
    }

    .ant-modal-body {
      padding: 0px 35px 18px 35px !important;
      @media (max-width: $max-resp-width) {
        background-color: #ebebeb;
        padding: 20px 10px !important;
      }

      .attendace-radio {
        span {
          &.ant-radio {
            display: none;
          }
        }
      }

      .ant-input {
        border-radius: 5px;
        background-color: #f7f7f8;
        border: none;
        @media (max-width: $max-resp-width) {
          width: 95%;
          padding-right: 10px;
        }
      }

      .attendace-radio {
        width: 100%;
        @media (max-width: $max-resp-width) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 95%;
        }

        .ant-radio-wrapper {
          font-weight: 500;
          border: 2px solid #7d7a83;
          border-radius: 5px;
          width: 29%;
          text-align: center;

          @media (max-width: $max-resp-width) {
            width: auto;
            padding: 8px 30px;
          }

          &:nth-child(1) {
            &.ant-radio-wrapper-checked {
              background-color: #27ae60;
              border-color: #27ae60;
              color: white;
            }
          }

          &:nth-child(2) {
            &.ant-radio-wrapper-checked {
              background-color: #eb5757;
              border-color: #eb5757;
              color: white;
            }
          }

          &:nth-child(3) {
            &.ant-radio-wrapper-checked {
              background-color: #f2bd1d;
              border-color: #f2bd1d;
              color: white;
            }
          }
        }

        .ant-radio-wrapper-checked {
          svg,
          path {
            fill: #ffffff;
          }
        }
      }
    }
  }

  .ant-modal-footer {
    background-color: red;
    border: none;
    text-align: left;
    padding: 40px;
    display: none;

    .ant-btn {
      &:nth-child(1) {
        display: none;
      }
    }

    .ant-btn-primary {
      background-color: $magenta-color;
      font-weight: 500;
      font-size: 12px;
      padding: 10px 65px;
      border-radius: 8px;

      &:hover,
      &:focus,
      &:visited {
        background: $honey-flower;
        color: $white-color;
      }
    }
  }

  .ant-modal-close {
    display: inline;
    top: 20px;

    .anticon {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ececec;
      border-radius: 100px;
    }
  }

  &.no-padding-modal {
    .ant-modal-body {
      padding: 0px 0px 0px 0px !important;
    }
  }
}
