.component-activity-banner {
  // padding: 20px 40px;
  color: #fff;
  border-radius: 4px;
  .text-container {
    position: relative;
    .description {
      font-size: 20px;
      text-transform: capitalize;
      @media (max-width: 500px) {
        text-align: center;
      }
      @media (max-width: 320px) {
        font-size: 18px;
      }
    }
    .name {
      transform: rotate(-3deg);
      font-size: 62px;
      max-width: 700px;
      font-family: COCOGOOSELETTERPRESS;
      margin-bottom: 12px;
      line-height: 1.3;
      @media (max-width: 500px) {
        font-size: 50px;
        text-align: center;
      }
      @media (max-width: 400px) {
        font-size: 40px;
      }
      @media (max-width: 320px) {
        font-size: 30px;
      }
    }
    .banner-btn {
      position: absolute;
      bottom: 15px;
      right: 15px;
    }
  }
}