@import "assets/stylesheet/variables.scss";

$shadowType1: 0px 0px 14px rgba(0, 0, 0, 0.1);

.add-children-to-batch-modal {
  .ant-modal-header {
    display: block;
    background-color: white;
    padding: 32px 31px 10px 31px;
    border: none;

    .ant-modal-title {
      color: #000;
      font-size: 24px;
    }
  }
  //FREAD-139
  tbody {
    display: block;
    height: 120px;
    overflow: auto;
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed; /* even columns width , fix width of table too*/
  }
  table tr td:nth-child(1),
  table tr th:nth-child(1) {
    width: 80px;
  }
  //FREAD-139
  .ant-modal-content {
    background-color: white;
    color: #7d7a83;
    border-radius: 8px;
    overflow: hidden;
    //FLV-139-2
    top: 25px;
    max-height: 460px;
    .ant-modal-body {
      padding: 15px 31px 0px !important;
      //FLV-139-2
      .teacher-listing-header {
        height: 80px;

        .ant-input {
          box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.06);
          width: 100%;
          border: none;

          &:hover {
            box-shadow: $shadowType1;
            border: none;
          }

          &:focus {
            box-shadow: $shadowType1;
            border: none;
          }
        }

        .ant-dropdown-trigger {
          box-shadow: $shadowType1;
          border: none;
        }
      }

      .children-attending-table {
        * {
          border: none;
        }

        table {
          border-collapse: collapse;
          border-spacing: 0 4px;
        }

        thead {
          th {
            background-color: #f6f6f6;
            padding: 10px;
            padding-top: 12px;

            span {
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              text-transform: uppercase;
            }

            .bar {
              width: 10px;
              height: 3px;
              background-color: #524e59;
              top: 9px;
              left: 5px;
              position: absolute;
              z-index: 1;
            }

            .ant-checkbox {
              border: 2px solid #524e59;
              box-sizing: border-box;
              border-radius: 4px;

              &.ant-checkbox-checked {
                .ant-checkbox-inner {
                  background: #219653;
                }

                border: 2px solid #219653;
              }
            }

            .ant-checkbox-checked .ant-checkbox-inner::after {
              display: none;
            }
          }
        }

        tbody {
          &::before {
            display: block;
            width: 100%;
            height: 8px;
            content: " s";
            text-indent: -999px;
          }

          tr {
            cursor: pointer;
            border: solid 3px white;

            &.ant-table-row {
              &.child-row {
                &.selected {
                  border: solid 3px #9134d8 !important;
                }
              }
            }

            &:hover {
              box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
            }

            td {
              background: white;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              color: #524e59;
            }
          }
        }

        td {
          img {
            width: 44px;
            height: 44px;
            border-radius: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  .ant-modal-footer {
    background-color: white;
    border: none;
    text-align: left;
    padding: 20px 31px;
    padding-bottom: 50px;
    //FLV-139-2
    //padding-top: 50px;
    padding-top: 0px;
    //FLV-139-2
    .ant-btn {
      &:nth-child(1) {
        display: none;
      }
    }

    .ant-btn-primary {
      background-color: $magenta-color;
      font-weight: 500;
      padding: 15px 70px;
      height: auto;
      border-radius: 8px;
      margin-left: 0px;

      &:hover,
      &:focus,
      &:visited {
        background: $honey-flower;
        color: $white-color;
      }

      span {
        font-size: 16px;
      }
    }
  }

  .ant-modal-close {
    display: inline;
    top: 20px;

    .anticon {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ececec;
      border-radius: 100px;
    }
  }
}
