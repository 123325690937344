.student-details-info {
  flex: 1;
  padding: 25px;
  .onboarding-questions-container {
    position: relative;
    .dotted-line {
      position: absolute;
      left: 6px;
      top: 0px;
      width: 2px;
      height: 100%;
      padding-top: 10px;
      .line-fill {
        background-image: url("../line.svg");
        width: 2px;
        height: 100%;
      }
    }
  }
  & > h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-transform: uppercase;

    /* Text Grey */

    color: #524e59;
    text-transform: uppercase;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    span {
      margin-left: 5px;
    }
  }
  .onboarding-question-details {
    padding-bottom: 35px;
    .question-title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .question-dot {
        margin-right: 20px;
        width: 14px;
        height: 14px;
        background-color: #9134d4;
        border-radius: 100%;
        position: relative;
        &::after {
          content: "";
          text-indent: -999px;
          width: 24px;
          height: 24px;
          border-radius: 100%;
          border: 2px solid #9134d4;
          position: absolute;
          left: 50%;
          transform: translate(-50%, -50%);
          top: 50%;
        }
      }
      .question-title-text {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */
        margin: 0px;
        letter-spacing: -0.222222px;

        /* #9351CD */

        color: #9351cd;
      }
      .question-divider {
        background-color: #ececec;
        height: 1px;
        flex: 1;
        margin-left: 15px;
      }
    }
    .question-info {
      padding-left: 35px;
      display: flex;
      margin-bottom: 13px;
      &:nth-last-child(1) {
        margin-bottom: 0px;
      }
      p {
        flex: 1.7;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;

        /* Text Grey */

        color: #524e59;
        &:nth-child(1) {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 10px;
          flex: 1;
          /* identical to box height, or 83% */

          text-transform: uppercase;

          color: #979797;
        }
      }
    }
  }
}
