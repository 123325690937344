@import "assets/stylesheet/variables.scss";

$grey-color: #524e59;
$shadowType1: 0px 0px 14px rgba(0, 0, 0, 0.10);

.add-subscription-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .seperator-20 {
    width: 100%;
    margin-bottom: 0;
  }

  .back-btn {
    color: $grey-color;
    border: none;
    margin: none;
    margin-left: -12px;
    font-size: 16px;
    width: auto;
    height: auto;
    padding: 0 !important;
    margin-left: 3px;
    text-transform: uppercase;
  }

  .create-subs-cta {
    display: flex;

    .export-btn {
      width: 148px;
      background-color: $magenta-color;
      border-radius: 10px;
      box-shadow: $shadowType1;
    }

    .cancel-btn {
      width: 148px;
      margin: 0 10px;
      background: #ECECEC;
      border-radius: 8px;
      border: none;
      color: $default-text-color;
      font-weight: 500;
      font-size: 16px;
    }

    .ant-btn-primary:hover,
    .ant-btn-primary:focus,
    .ant-btn-primary:visited,
    .ant-btn-primary:active {
      background: $honey-flower;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 500;
      color: $white-color;
      //width: 100%;
      border: none;
      outline: none;
    }
  }


}

.add-subscription-wrapper {
  border-radius: 6px;
  background-color: $white-color;
  padding: 5px 0;
}


.heading1 {
  font-size: 16px;
  color: $dark-magenta;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 32px;
}

.subscription-form {


  .ant-form-item-label {
    line-height: normal;
  }

  .ant-radio-group {
    width: 100%;
  }

  .ant-form-item .ant-form-item-label label,
  .custom-form-label {
    position: relative;
    padding-bottom: 8px;
    min-height: 22px;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    color: $light-gray-color3;
    text-transform: uppercase;

    &:after {
      display: none;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-input,
  .ant-input:focus,
  .ant-input:hover,
  .ant-input:active,
  .ant-select-selection,
  .ant-input-number {
    background: rgba(236, 236, 236, 0.2) !important;
    border-radius: 8px;
    height: 56px;
    border: none;
    box-shadow: none;
    font-size: 16px;
    font-weight: 500;
    color: $default-text-color;
    outline: none;
    padding: 0 16px;
    width: 100%;

  }

  .ant-input-group-addon {
    background-color: #f0eff1;
    border: none;
    border-radius: 20px 0 0 20px;
    font-size: 20px;
    font-weight: 500;
    color: $default-text-color;
    padding-right: 7px;
  }

  .ant-input-group-addon + .ant-input,
  .ant-input-group-addon + .ant-input:hover,
  .ant-input-group-addon + .ant-input:focus {
    border-radius: 0 20px 20px 0;
    padding-left: 4px;
  }

  .ant-calendar-picker {
    width: 100%;
  }

  .ant-select-selection__rendered,
  .ant-input-number-input-wrap {
    padding: 0;
    margin: 0;
    line-height: 56px;
  }

  .ant-input-number-input {
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    color: $default-text-color;
  }

  .ant-select-selection-selected-value {
    line-height: 56px;
  }

  textarea.ant-input,
  textarea.ant-input:hover,
  textarea.ant-input:focus,
  textarea.ant-input:active,
  textarea.ant-input:visited {
    min-height: 158px;
    padding: 10px 16px;
  }

  .ant-form-item-children {
    display: block;
  }


  //.ant-btn-md,
  .ant-btn-primary:hover,
  .ant-btn-primary:focus,
  .ant-btn-primary:visited,
  .ant-btn-primary:active {
    background: $honey-flower;
    border-radius: 8px;
    height: 56px;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    color: $white-color;
    width: 100%;
    border: none;
    outline: none;
    margin-top: 30px;
  }

  .ant-radio-wrapper {
    display: flex;
  }

  .ant-radio + span {
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    color: $default-text-color;
    margin-left: 10px;
  }

  .ant-radio-inner {
    border: solid 2px $magenta-color !important;
    height: 24px;
    width: 24px;
  }

  .ant-radio-checked .ant-radio-inner::after {
    height: 14px;
    width: 14px;
    background-color: $magenta-color;
  }

  .ant-form-item-children {
    line-height: normal;
  }


  .error-label {
    color: #F24E29;
  }

  .button-container {
    display: flex;

    .button {
      cursor: pointer;
      max-height: 100px;
      padding: 0 10px;
    }
  }

  .separator {
    height: 2px;
    margin: 0 2px 32px 0;
    background-color: #ececec;
  }

  .custom-dropdown {
    background-color: $light-gray-color4;
    border-radius: 20px 20px 0 0;
    border: none;
    box-shadow: 5px 8px 24px rgba(0, 0, 0, 0.06);

    &:before {
      content: "";
      position: absolute;
      bottom: -20px;
      left: 0;
      background: $light-gray-color4;
      width: 100%;
      height: 20px;
    }

    &.ant-select-dropdown-placement-bottomLeft {
      border-radius: 0 0 20px 20px;

      &:before {
        top: -20px;
        bottom: auto;
      }
    }

    .ant-select-dropdown-menu {
      overflow-x: hidden;
    }

    .ant-select-dropdown-menu-item {
      font-size: 20px;
      font-weight: 500;
      color: $default-text-color;
      padding: 10px 16px;

      &:hover,
      &:active,
      &:focus,
      &-active {
        background-color: $magenta-color;
        color: $white-color;
      }
    }
  }

  .grade-selector {
    .ant-input-number {
      background-color: rebeccapurple !important;
    }

    .ant-select-selection__rendered {
      ul {
        li {

          &.ant-select-selection__choice {
            border: none;
            background-color: transparent;
            padding: 0;
            align-items: center;

            .ant-select-selection__choice__remove {
              display: none;
            }

          }

          &.ant-select-selection__choice:not(.ant-select-selection__choice:nth-last-of-type(2)):after {
            content: ",";
            font-size: 20px;
            bottom: 10px;
          }
        }
      }
    }

    .ant-select-arrow {
      top: 29px;

      svg {
        fill: #525860;
        font-size: 13px;
        font-weight: 500;
      }
    }
  }
}

.multi-select-dropdown {
  .ant-select-dropdown-menu-item {
    align-items: center;
    display: flex;
    font-size: 20px;
    font-weight: 500;
    color: #524e59;
    padding: 8px 0 8px 50px;

    .dropdown-check-mark {
      height: 23px;
      width: 23px;
      border: solid 2px $magenta-color;
      border-radius: 50px;
      position: absolute;
      left: 15px;

      &.active {
        background: url('../../../../assets/image/circle-check.svg') no-repeat -1px -1px;
        background-size: 23px;
      }
    }

    &:hover {
      &:not(.ant-select-dropdown-menu-item-disabled) {
        background-color: $magenta-color;
        color: #fff;
      }

      .dropdown-check-mark {
        border: solid 2px #fff;

        &.active {
          background-color: #fff;
          background-size: 23px;
        }
      }

    }
  }
}

