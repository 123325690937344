.student-form {
  .error-message {
    color: #e84646;
    font-size: 12px;
  }
  .form-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 10px;
    /* identical to box height, or 71% */

    text-transform: uppercase;

    /* Primary Purple */

    color: #4c1a73;
    margin-bottom: 16px;
  }
  .form-seconday-title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */

    letter-spacing: -0.222222px;

    /* Text Grey */
    margin-bottom: 24px;
    color: #524e59;
  }
  .form-column {
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 10px;
      /* identical to box height, or 83% */

      text-transform: uppercase;
      margin-bottom: 8px;
      color: #979797;
    }
  }
  .ant-input,
  .ant-select-selection {
    box-shadow: none;
    padding: 30px 16px;
    border-radius: 8px;
    background: #fbfbfb;
    font-weight: 500;
    border: 0px;
    &::placeholder {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      /* identical to box height */

      letter-spacing: -0.222222px;

      /* Text Grey */

      color: #524e59;

      opacity: 0.2;
    }
  }
  .ant-select-selection {
    padding: 15px;
  }
  .ant-select-selection-selected-value {
    font-size: 14px;
    font-weight: 500;
  }
  .ant-row {
    margin-bottom: 24px;
    display: flex;
    // align-items: center;
  }
  .form-container {
    border-bottom: 1px solid #ececec;
  }
  .center-align {
    display: flex;
    align-items: center;
  }
  .outline-btn {
    border: 0px;
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #9134d8;
    }
    &:hover {
      background: none;
    }
  }
}
