@import "assets/stylesheet/variables.scss";

$shadowType1: 0px 0px 14px rgba(0, 0, 0, 0.10);

.subscription-listing {
  &-header {
    display: flex;
    flex-direction: row;

    .export-btn {
      background-color: $magenta-color;
      border-radius: 10px;
      box-shadow: $shadowType1;
    }
  }
}

.subscription-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 50%;
  position: absolute;
  z-index: 999;
  background: white;
  opacity: 0.6;
}

.subscription-card-section {
  border-radius: 6px 6px 0 0;
  background-color: $white-color;
  margin-top: 30px;

  .listing-row-primary-text {
    font-size: 16px;
    font-weight: 500;
    color: $default-text-color;
    margin: 3px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 350px !important;

    &:nth-of-type(1) {
      overflow: unset;
    }

    .grade-cell {
      display: flex;
      align-items: center;

      .tooltip {
        position: relative;
        display: inline-block;
        border-bottom: 1px dotted black;
      }

      .tooltip .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;

        /* Position the tooltip */
        position: absolute;
        z-index: 1;
      }

      .grade-count {
        margin-left: 5px;
        display: flex;
        flex-direction: row;
        color: #BDBDBD;
        font-size: 10px;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        border-radius: 100px;
        border: 0.826087px solid #BDBDBD;
        position: relative;

        span {
          display: flex;
        }
      }
    }

    .other-grades-popup {
      position: absolute;
      z-index: 1;
      background: #fff;
      box-shadow: 0.7px 0.6px 13px 1px rgba(0, 0, 0, 0.103775);
      border-radius: 8px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: #000000;
      padding: 12px;
      top: 31px;
      left: -17px;
    }


  }

  .ant-table-thead {
    background: $athens-gray;
    border-radius: 8px;

    tr {
      border-radius: 8px !important;

    }

    th {
      font-size: 12px;
      color: #524E59;
      font-weight: 500;
      text-transform: uppercase;
      padding: 12px 10px;
      border-bottom: none;
      text-align: left;

    }

    th:first-of-type {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
    }

    th:nth-of-type(1) {
      padding-left: 4%;
    }

    th:nth-of-type(2) {
      width: 11%;
    }


    th:last-of-type {
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }
  }

  .ant-table-placeholder {
    border-top: none;
    border-bottom: none;
    background: transparent;
  }

  .ant-table .ant-table-tbody {
    > tr {
      cursor: pointer;


      > td {
        vertical-align: middle;
        border-bottom: none;
        text-align: left;
      }

      td:nth-of-type(1) {
        padding-left: 4%;
      }

      td:nth-of-type(2) {
        flex-basis: 11%;
      }
    }

    .subscription-row {
      &.disable {
        cursor: not-allowed;

        &:hover {
          box-shadow: none;
        }
      }
    }

    > tr:hover {
      box-shadow: 1px 4px 15px 1px rgba(0, 0, 0, 0.11);
      border-radius: 8px;

      > td {
        background: white !important;
      }

      td:first-of-type {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      td:last-of-type {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }


    }

    &:before {
      content: "@";
      display: block;
      line-height: 10px;
      text-indent: -99999px;
    }
  }

  .ant-pagination {
    padding: 16px;
    text-align: right;
  }
}

