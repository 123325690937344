@import "assets/stylesheet/variables.scss";

.admin-login {
  &-container {
    box-sizing: border-box;
    background: $white-color;
    padding: 80px;
    display: flex;
    flex-direction: row;
    @media (max-width: $max-resp-width) {
      padding: 10px;
      padding-top: 70px;
      padding-left: 25px;
      padding-right: 25px;
    }

    .admin-logo {
      margin: 0 0 70px 0;
    }

    h1 {
      font-size: 56px;
      font-weight: bold;
      margin-bottom: 30px;
      color: $dark-magenta;
      line-height: 57px;
    }

    .custom-form {
      .ant-btn {
        border-radius: 20px;
        height: 56px;
        display: block;
        margin-top: 70px;
        background-color: $magenta-color;

        &:disabled {
          opacity: 0.5;
        }
      }


      &.admin-login-form {
        .ant-input,
        .ant-input:focus,
        .ant-input:hover,
        .ant-input:active,
        .ant-select-selection,
        .ant-input-number {
            background: #fff;
            border-radius: 10px;
            height: 56px;
            border: none;
            box-shadow: 5px 8px 24px rgba(0, 0, 0, 0.06);
            font-size: 20px;
            font-weight: 500;
            color: $default-text-color;
            outline: none;
            padding: 0 16px;
            width: 100%;
        }
        .ant-form-item{
          margin-bottom: 22px;
        }
      }
    }

    .link-text {
      display: inline-block;
      font-size: 14px;
      margin: 16px 0 0 5px;
      color: $magenta-color;
      cursor: pointer;
    }

    .sm-desc {
      font-size: 14px;
      color: $default-text-color;
    }
  }

  &-left,
  &-right {
    height: calc(100vh - 160px);
  }

  &-left {
    width: 400px;
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    @media (max-width: $max-resp-width) {
      margin-left: 0px;
      width: 100%;
    }
  }

  &-right {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    @media (max-width: $max-resp-width) {
      display: none;
    }
  }

  &-banner {
    height: 80%;
  }

  &-desc {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 40px;
    color: $default-text-color;
  }

  &-form {
    display: flex;
    flex-direction: column;
  }
}


.reset-link-modal {
  padding: 42px;
  text-align: center;

  .success-mail-icon {
    width: 120px;
  }

  &-title {
    font-size: 28px;
    color: $default-text-color;
    font-weight: 600;
    margin: 40px 0 10px 0;
  }

  &-desc {
    font-size: 16px;
    color: $default-text-color;
    line-height: 24px;
    margin-bottom: 50px;

    span {
      color: $magenta-color;
    }
  }

  .ant-btn {
    border-radius: 20px;
    height: 56px;
    display: block;
    background-color: $magenta-color;
    width: 377px;
    margin: auto;
  }
}

.form-error {
  color: $flamingo;
}
