.view-magic-story {
    width: 100vw;
    height: 100vh;
    background-color: #0a0a0b;
    overflow: hidden;

    .top-section {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-top: 72px;
        letter-spacing: -0.6px;
        color: #ffffff;

        .find-text {
            font-size: 30px;
            font-weight: bold;
        }

        .find-sub-text {
            font-size: 24px;
            margin-top: 22px;
        }
    }

    .bottom-section {
        position: absolute;
        bottom: 34px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        transition: 1s all;
        cursor: pointer;

        &.bottom-to-center {
            position: absolute;
            bottom: 35%;
            left: 50%;
            transform: translate(-50%, 50%) scale(1.7, 1.7);
            transition: 1s all;
            cursor: pointer;
            .circle-container {
                bottom: -35%;
                width: 360px;
            }
        }

        img {
            z-index: 1;
            height: 70px;
            width: 70px;
            margin: 0 auto;
            margin-bottom: 20px;
        }

        .magic-text {
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: -0.38px;
            color: #ffffff;
        }

        .circle-container {
            position: absolute;
            height: 185px;
            width: 500px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            left: -210%;
            bottom: -15%;
            z-index: -1;

            .circle {
                border-radius: 50%;
                border: 2px solid #e23b72;
                width: 120px;
                height: 120px;
                position: absolute;
                opacity: 0;
                animation: scaleIn 4s infinite cubic-bezier(.36, .11, .89, .32);

                &.first {
                    animation-delay: -3s;
                }

                &.second {
                    animation-delay: -1.5s;
                }

                &.third {
                    animation-delay: -0s;
                }

                &.fourth {
                    animation-delay: 0s;
                }
            }

            @keyframes scaleIn {
                from {
                    transform: scale(.5, .5);
                    opacity: .5;
                }

                to {
                    transform: scale(1.6, 1.6);
                    opacity: 0;
                }
            }
        }
    }
}